<template>
<AppPermission :permission="this.$permissions.voucher.data?? '' ">
    <div >
      <div class="container my-4">
        <div class="row m-0 p-0">
          <div class="mainLeft">
            <div class="main-title">
              <span>{{ $t('message.VoucherIndex.title') }}</span>
            </div>
            <div>
              <div class="card-header mx-0 px-0" id="typeHeader">
                <div class="row">
                  <div class="col-8 text-title">
                    <label>{{ $t('message.common.numberRecord') }}:</label>
                  </div>
                  <div class="col-4">
                    <select :value="numberRecord" name="number_records" id="number_records" class="number_records" @change="onChange($event)">
                      <option value="15">15</option>
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div class="card" id="typeCard">
                <div class="card-header mx-0 px-0" id="typeHeader">
                    <div class="row mx-2">
                        <div class="col-9">
                            <label for="" class="typeTitle">{{ $t('message.VoucherIndex.branch') }}</label>
                        </div>
                        <div class="col-1">
                            <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('branchBody', 'branchDown', 'branchUp')">
                                <i class="fa fa-chevron-down dp-hide" id="branchDown"></i>
                                <i class="fa fa-chevron-up" id="branchUp"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="card-body my-0 pb-3 pt-0" id="branchBody">
                    <div class="row">
                        <div class="col-12">
                            <SelectSearch :placeholder="$t('message.VoucherIndex.placeholder.branch')" :modelValue="filterQuery.branches" :defaultOption="this.branches" @update:modelValue="doFilterSearch" :label="'branches'"/>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Filter trạng thái -->
            <div class="card mt-4" id="typeCard">
                <div class="card-header mx-0 px-0 pb-0" id="typeHeader">
                    <div class="row mx-2">
                        <div class="col-9">
                            <label class="typeTitle">{{ $t('message.VoucherIndex.status') }}</label>
                        </div>
                    </div>
                </div>
                <div class="card-body my-0 py-2" id="status">
                    <template v-if="this.$constant.optionStatusCouponVoucher">
                        <div class="row mb-1 ml-0" v-for="option in this.$constant.optionStatusCouponVoucher" :key="option.id">
                            <label class="container-radio ml-2">{{option.label}}
                                <input type="radio" name="status" v-model="this.filterQuery.status" :checked="this.filterQuery.status === option.value" :value="option.value" class="cheker mr-2" @change="doFilter('status', option.value)">
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </template>
                </div>
            </div>
          </div>
        
          <div class="mainRight">
            <div>
                <div class="row">
                    <form class="col-lg-5">
                        <InputQuickSearch :placeholder="$t('message.VoucherIndex.placeholder.search')" @filterSearch="changeQuickSearch" :vouchersSearch="true" />
                    </form>
                    <div class="col-lg-7 d-flex justify-content-end">
                        <button type="button" @click="clearFilter()" class="btn btn-default"><i class="fa fa-filter mr-1"></i>{{ $t('message.button.removeFilter') }}</button>
                        <!-- <div class="dropdown" v-if="this.arrayChecked?.length > 0 && entries?.length > 0">
                            <button class="btn btn-save dropdown-toggle" type="button" id="dropdownMenuButton"
                                style="padding:7px" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fa fa-ellipsis-v px-2"></i>Thao tác
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <a class="dropdown-item">
                                    <i class="fa fa-lock mr-2"></i>
                                    Ngừng hoạt động
                                </a>
                                <a class="dropdown-item">
                                    <i class="fa fa-trash-alt mr-2"></i>
                                    Xóa
                                </a>
                                <a class="dropdown-item">
                                    <i class="fas fa-file-export mr-2"></i>
                                    Xuất file
                                </a>
                            </div>
                        </div> -->
                        <AppPermission :permission="this.$permissions.voucher.create?? '' ">
                            <button class="btn btn-save ml-1" @click="createVoucher()"><i class="fa fa-plus"></i> {{ $t('message.VoucherIndex.button.add') }} </button>
                        </AppPermission>
                        <div id="loc" v-on:mouseleave="onMouseLeave">
                            <SelectField :fields="fields" label="voucher"></SelectField>
                        </div>
                    </div>
                </div>
                <div>
                    <Pagination :value="paginate" :record="this.numberRecord" @change="pageChange"/>
                </div>
                <div class="row mt-2">
                    <div class="col-12" v-if="this.arrayChecked?.length > 0 && entries?.length > 0">
                        {{ $t('message.common.numberRecordSelected', {number: this.arrayChecked?.length}) }}
                        <a href="javascript:;" @click="removeChecked()">
                            <i class="fa fa-times text-danger" aria-hidden="true"></i>
                        </a>
                    </div>
                </div>
                <div class="row mx-0 my-2" id="divTable">
                    <table class="table table-index table-striped lstProduct" >
                        <thead>
                            <tr>
                                <!-- <th scope="col" class="checkbox-table"><input v-model="checkedAll" @change="onCheckedAll(checkedAll)" type="checkbox"/></th> -->
                                <template v-for="field in fields" :key="field.field">
                                    <th scope="col" v-if="field.selected" style="vertical-align: middle !important;">
                                        <TableSortField :current="sortData.field" :no-sort="!field.isColumn"
                                                        :class="field.class" :name="field.name" :field="field.field" :style="field.style" class="description"/>
                                    </th>
                                </template>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-if="entries.length > 0">
                                <template v-for="(entry, index) in entries" :key="entry.id" >
                                    <tr class="master-row" :class="entry.id === this.voucherId ? 'backgroup__detail border__master' :''" :id="'row_' + (index + 1)">
                                        <!-- <td> <input v-model="entry.checked" type="checkbox"  @change.stop="countChecked(entry.id)" class="prdChecked"
                                                    :id="entry.id" :name="entry.name" :value="entry.code" :checked="isExistInArrayChecked(entry, this.arrayChecked)"></td> -->
                                        <template v-for="field in fields" :key="field.field">
                                            <td class="ow-break-word py-0" v-if="field.selected" style="vertical-align:middle !important;" :style="'min-width:110px'">
                                                <a :href="'#row_' + index" class="text-dark tagA" @click="detailVoucher(entry.id, entry)">
                                                    <div v-if="field.field==='startDate'" :style="field.style" :class="field.class"><span v-text="d(entry.startDate)"></span></div>
                                                    <div v-else-if="field.field==='endDate'" :style="field.style" :class="field.class"><span v-text="d(entry.endDate)"></span></div>
                                                    <div v-else-if="field.field==='value'" :style="field.style" :class="field.class"><span v-text="n(entry.value)"></span></div>
                                                    <div v-else-if="field.field==='createBy'" :style="field.style" :class="field.class"><span v-text="entry.createBy"></span></div>
                                                    <div v-else-if="field.field==='slot'" :style="field.style" :class="field.class"><span v-text="(entry.details_count ? n(entry.details_count) : 0)"></span></div>
                                                    <div v-else v-text="renderField(entry, field.field, field)" :title="renderField(entry, field.field, field)" :class="field.class" class="description">
                                                    </div>
                                                </a>
                                            </td>
                                        </template>
                                    </tr>
                                    <tr v-if="voucherId === entry.id" :class="[ entry.id === voucherId ? 'backgroup__detail border__header-detail border-top-0' : '', errorClass]">
                                        <!-- <td></td> -->
                                        <td :colspan="fields.length" class="pb-0">
                                            <div class="row detail-prd-row">
                                                <div class="col-2 mt-2" @click="showContent('info')">
                                                    <span :class="[ headerActive === 'info' ? 'backgroup__detail-active' : '', 'head-detail']">
                                                        {{ $t('message.VoucherIndex.tabs.info') }}
                                                    </span>
                                                </div>
                                                <div class="col-2 mt-2" @click="showContent('listVoucher')">
                                                    <span :class="[ headerActive === 'listVoucher' ? 'backgroup__detail-active' : '', 'head-detail']">
                                                        {{ $t('message.VoucherIndex.tabs.list_voucher') }}
                                                    </span>
                                                </div>
                                                <div class="col-2 mt-2" @click="showContent('history')">
                                                    <span :class="[ headerActive === 'history' ? 'backgroup__detail-active' : '', 'head-detail']">
                                                        {{ $t('message.VoucherIndex.tabs.history') }}
                                                    </span>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr class="detail-row bg-white border__body-detail" v-if="voucherId === entry.id && headerActive === 'info'">
                                        <!-- <td></td> -->
                                        <td :colspan="fields.length + 1" class="border-top-0">
                                            <div class="mt-2 pb-4 detail__user pl-2">
                                                <div class="row mt-2 detail-prd-row">
                                                    <div class="col-5 px-4">
                                                        <div class="row border-bottom-1 mt-2 py-2">
                                                            <div class="col-5">
                                                                {{ $t('message.VoucherIndex.code') }}:
                                                            </div>
                                                            <div class="col-7 ow-break-word">                                                            
                                                                <b>{{ entry.code }}</b>
                                                            </div>
                                                        </div>
                                                        <div class="row border-bottom-1 mt-2 py-2">
                                                            <div class="col-5 lableOS">
                                                                {{ $t('message.VoucherIndex.time') }}:
                                                            </div>
                                                            <div class="col-7 ow-break-word lableOS pr-0">                                                            
                                                                {{ d(entry.startDate) + ' - ' + d(entry.endDate)}}
                                                            </div>
                                                        </div>
                                                        <div class="row border-bottom-1 mt-2 py-2">
                                                            <div class="col-5">
                                                                {{ $t('message.VoucherIndex.categories') }}:
                                                            </div>
                                                            <div class="col-7 ow-break-word">
                                                                <template v-if="entry.isFullCategories">
                                                                    {{ $t('message.VoucherIndex.all_categories') }}
                                                                </template>
                                                                <template v-else-if="!entry.isFullCategories && entry.categories">
                                                                    <template v-for="(cate, index) in entry.categories" :key="cate.id">
                                                                        <span v-if="index == 0">{{ $t('message.VoucherIndex.group') }}: {{ cate.categoryName }}, </span>
                                                                        <span v-else>{{ cate.categoryName }}, </span>
                                                                    </template>
                                                                </template>
                                                            </div>
                                                        </div>
                                                        <div class="row border-bottom-1 mt-2 py-2">
                                                            <div class="col-5">
                                                                {{ $t('message.VoucherIndex.product') }}:
                                                            </div>
                                                            <div class="col-7 ow-break-word">
                                                                <template v-if="entry.products">
                                                                    <template v-for="prod in entry.products" :key="prod.id">
                                                                        <span>{{ prod.productName }}, </span>
                                                                    </template>
                                                                </template>
                                                            </div>
                                                        </div>
                                                        <div class="row border-bottom-1 mt-2 py-2">
                                                            <div class="col-5">
                                                                {{ $t('message.VoucherIndex.status') }}:
                                                            </div>
                                                            <div class="col-7 ow-break-word">
                                                                {{  entry.status }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-5 px-4">
                                                        <div class="mx-1">
                                                            <div class="col-12">
                                                                <div class="row border-bottom-1 mt-2 py-2">
                                                                    <div class="col-5 lableOS">
                                                                        {{ $t('message.VoucherIndex.name') }}:
                                                                    </div>
                                                                    <div class="col-7 ow-break-word">
                                                                        {{ entry.name }}
                                                                    </div>
                                                                </div>
                                                                <div class="row border-bottom-1 mt-2 py-2">
                                                                    <div class="col-5">
                                                                        {{ $t('message.VoucherIndex.value') }}:
                                                                    </div>
                                                                    <div class="col-7 ow-break-word">
                                                                        {{ n(entry.value) }}
                                                                    </div>
                                                                </div>
                                                                <div class="row border-bottom-1 mt-2 py-2">
                                                                    <div class="col-5">
                                                                        {{ $t('message.VoucherIndex.min_total_price') }}:
                                                                    </div>
                                                                    <div class="col-7 ow-break-word">
                                                                        {{ n(entry.minPrice) }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-2" id="noteRO">  
                                                        <div class="row p-0 font-italic">
                                                            <div class="col-12">
                                                                <span v-text="entry.note ? entry.note : $t('message.VoucherIndex.note')" class="noteStyle px-4 text-gray font-italic"></span>
                                                                <!-- <textarea v-model="entry.note" :id="'note_'+ entry.id" :placeholder="$t('message.ProductIndex.note')" class="noteStyle px-4 text-gray font-italic"></textarea> -->
                                                                <!-- <i class="fa fa-pencil-alt text-gray" id="fa_edit"></i> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row detail-prd-row flex-end mt-4 pr-4" >
                                                    <AppPermission :permission="[this.$permissions.voucher.update ?? '' , this.$permissions.voucher.releaseVoucher ?? '']">
                                                        <button type="button" class="btn btn-save mx-1" @click="update(entry)">
                                                            <i class="fa fa-check"></i>
                                                            {{ $t('message.VoucherIndex.button.update') }}
                                                        </button>
                                                    </AppPermission>
                                                      <AppPermission :permission="this.$permissions.voucher.create?? '' ">
                                                        <button type="button" class="btn btn-save mx-1 pr-4"  @click="coppy(entry, 'copy')">
                                                            <i class="fa fa-copy" aria-hidden="true"></i>
                                                            {{ $t('message.VoucherIndex.button.copy') }}
                                                        </button>
                                                    </AppPermission>
                                                      <AppPermission :permission="this.$permissions.voucher.remove?? '' ">
                                                        <button type="button" class="btn btn-danger mr-4" @click="RemoveVoucher(entry.id, entry.name)">
                                                            <i class="fa fa-trash-alt"></i>
                                                            {{ $t('message.VoucherIndex.button.remove') }}
                                                        </button>
                                                    </AppPermission>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr class="detail-row bg-white border__body-detail" v-if="voucherId === entry.id && headerActive === 'listVoucher'">
                                        <td :colspan="fields.length + 1" class="border-top-0">
                                            <div class="row detail-prd-row mx-0 mt-3 pr-4">
                                                <div class="col-12 p-0">
                                                    <table class="w-100">
                                                        <thead>
                                                            <tr class="backgroup__detail table-header border-1">
                                                                <!-- <th scope="col" class="pl-2 cell-check"><input type="checkbox" name="" id=""></th> -->
                                                                <th scope="col" class="pl-4 cell-code-coupon">{{ $t('message.VoucherIndex.table.list_voucher.code') }}</th>
                                                                <th scope="col" class="pl-4 cell-auto"></th>
                                                                <th scope="col" class="pl-4 cell-release-coupon">{{ $t('message.VoucherIndex.table.list_voucher.release_date') }}</th>
                                                                <th scope="col" class="pl-4 cell-auto">{{ $t('message.VoucherIndex.table.list_voucher.end_date') }}</th>
                                                                <th scope="col" class="pl-4 cell-auto"></th>
                                                                <th scope="col" class="pl-4 cell-status-coupon">{{ $t('message.VoucherIndex.status') }}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr class="table-header-search border">
                                                                <!-- <th scope="col" class="pl-2 cell-check"></th> -->
                                                                <th scope="col" class="pl-4 cell-code-coupon position-relative">
                                                                    <input type="text" :placeholder="$t('message.VoucherIndex.table.list_voucher.placeholder.search')" class="form-control input-custom bg-transparent pr-4" autocomplete="off" v-model="this.voucherDefault.search" @input="onInputFilterCouponDetail()">
                                                                    <i class="fa fa-search mr-2 text-gray"></i>
                                                                </th>
                                                                <th scope="col" class="pl-4 cell-auto"></th>
                                                                <th scope="col" class="pl-4 cell-release-coupon"></th>
                                                                <th scope="col" class="pl-4 cell-auto"></th>
                                                                <th scope="col" class="pl-4 cell-auto"></th>
                                                                <th scope="col" class="pl-4 cell-status-coupon position-relative">
                                                                    <SelectSearch :modelValue="this.voucherDefault.status" :readonly="true" :defaultOption="this.$constant.optionStatusCoupon" @update:modelValue="doFilterSearchCouponList" :label="'statusCoupon'"/>
                                                                </th>
                                                            </tr>
                                                            <template v-if="this.voucherDefault.filter?.length > 0">
                                                                <tr class="table-header border" v-for="(voucher, index) in this.voucherDefault.filter" :key="voucher.id">
                                                                    <template v-if="index >= (this.paginateDetail.currentPage - 1)*recordVoucher && index < this.paginateDetail.currentPage*recordVoucher">
                                                                        <!-- <th scope="col" class="pl-2 cell-check"><input type="checkbox" name="" id=""></th> -->
                                                                        <th scope="col" class="pl-4 cell-code-coupon position-relative font-weight-normal" v-text="voucher.code"></th>
                                                                        <th scope="col" class="pl-4 cell-auto font-weight-normal"></th>
                                                                        <th scope="col" class="pl-4 cell-release-coupon font-weight-normal" v-text="d(voucher.releaseDate)"></th>
                                                                        <th scope="col" class="pl-4 cell-auto font-weight-normal" v-text="d(voucher.endDate)"></th>
                                                                        <th scope="col" class="pl-4 cell-auto font-weight-normal"></th>
                                                                        <template v-if="voucher.invoiceId">
                                                                            <th scope="col" class="pl-4 cell-status-coupon font-weight-normal text-right" :class="voucher.invoiceId ? 'text-primary' : ''" @click="showHistoryDetail(voucher.invoiceId)" v-text="voucher.status"></th>
                                                                        </template>
                                                                        <template v-else>
                                                                            <th scope="col" class="pl-4 cell-status-coupon font-weight-normal text-right" :class="voucher.orderId ? 'text-primary' : ''" @click="showHistoryDetailInOrder(voucher.orderId)" v-text="voucher.status"></th>
                                                                        </template>
                                                                        
                                                                    </template>
                                                                </tr>
                                                            </template>
                                                        </tbody>
                                                    </table>
                                                    <div class="no-data border border-top-0" v-if="this.voucherDefault.filter?.length == 0">
                                                        <span>{{ $t('message.common.notFound') }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row detail-prd-row mx-0 mt-3 pr-4">
                                                <div class="col-12">
                                                    <Pagination :value="paginateDetail" :record="recordVoucher" v-if="this.paginateDetail.totalRecord >= recordVoucher"/>
                                                </div>
                                            </div>
                                            <div class="row detail-prd-row mx-0 my-3 pr-4">
                                                <div class="col-9 flex-end align-items-center"> 
                                                    {{$t('message.VoucherIndex.table.list_voucher.number_voucher')}}:
                                                </div>
                                                <div class="col-1 p-0 d-flex align-items-center">
                                                    <select :value="recordVoucher" name="number_records" id="number_records" class="number_records mt-0" @change="changeRecordVoucher">
                                                        <option value="10">10</option>
                                                        <option value="50">50</option>
                                                        <option value="100">100</option>
                                                        <option value="150">150</option>
                                                        <option value="200">200</option>
                                                    </select>
                                                </div>
                                                <div class="col-2 align-items-center pr-0">
                                                    <!-- Xuất file danh sách -->
                                                    <button type="button" class="btn btn-secondary text-white mx-1" @click="exVoucherCode(this.voucherDefault.filter)">
                                                        <i class="fa fa-file-export" aria-hidden="true"></i>
                                                        {{$t('message.VoucherIndex.table.list_voucher.button.export')}}
                                                    </button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr class="detail-row bg-white border__body-detail" v-if="voucherId === entry.id && headerActive === 'history'">                                            
                                        <td :colspan="fields.length + 1" class="border-top-0">
                                            <div class="row detail-prd-row mx-0 mt-3 pr-4">
                                                <div class="col-12 p-0">
                                                    <table class="w-100">
                                                        <thead>
                                                            <tr class="backgroup__detail table-header border-1">
                                                                <th scope="col" class="pl-2 cell-auto">{{$t('message.VoucherIndex.table.history.code')}}</th>
                                                                <th scope="col" class="pl-4 cell-auto">{{$t('message.VoucherIndex.table.history.time')}}</th>
                                                                <th scope="col" class="pl-4 cell-auto">{{$t('message.VoucherIndex.table.history.sold_by')}}</th>
                                                                <th scope="col" class="pl-4 cell-auto">{{$t('message.VoucherIndex.table.history.customer')}}</th>
                                                                <th scope="col" class="pl-4 cell-auto">{{$t('message.VoucherIndex.table.history.branch')}}</th>
                                                                <th scope="col" class="pl-4 cell-code-coupon">{{$t('message.VoucherIndex.table.history.revenue')}}</th>
                                                                <th scope="col" class="pl-4 cell-status-coupon">{{$t('message.VoucherIndex.table.history.value_voucher')}}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>                                    
                                                            <tr class="table-header border">
                                                                <td scope="col" class="pl-2 cell-auto text-primary"></td>
                                                                <td scope="col" class="pl-4 cell-auto font-weight-normal"></td>
                                                                <td scope="col" class="pl-4 cell-auto font-weight-normal"></td>
                                                                <td scope="col" class="pl-4 cell-auto font-weight-normal"></td>
                                                                <td scope="col" class="pl-4 cell-auto font-weight-normal"></td>
                                                                <td scope="col" class="pl-4 cell-auto font-weight-normal">{{ n(entry.totalPrice)}}</td>
                                                                <td scope="col" class="pl-4 cell-auto font-weight-normal"> {{ n(entry.totalValue)}}</td>
                                                            </tr>
                                                            <template v-if="entry.historyUse?.length > 0">
                                                                <tr class="table-header border" v-for="history in entry.historyUse" :key="history.id">
                                                                    <td scope="col" class="pl-2 cell-auto text-primary" @click="history.orderId ? showHistoryDetailInOrder(history.orderId) : showHistoryDetail(history.invoiceId)">{{ history.code }}</td>
                                                                    <td scope="col" class="pl-4 cell-auto font-weight-normal">{{ history.date }}</td>
                                                                    <td scope="col" class="pl-4 cell-auto font-weight-normal">{{ history.createBy }}</td>
                                                                    <td scope="col" class="pl-4 cell-auto font-weight-normal">{{ history.customerName }}</td>
                                                                    <td scope="col" class="pl-4 cell-auto font-weight-normal">{{ history.branchName }}</td>
                                                                    <td scope="col" class="pl-4 cell-code-coupon font-weight-normal">{{ n(history.totalPrice) }}</td>
                                                                    <td scope="col" class="pl-4 cell-status-coupon font-weight-normal">{{ n(history.valueVoucher) }}</td>
                                                                </tr>
                                                            </template>
                                                        </tbody>
                                                    </table>
                                                    <div class="no-data border border-top-0" v-if="!entry.historyUse || entry.historyUse?.length == 0">
                                                        <span>{{ $t('message.common.notFound') }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row detail-prd-row mx-0 my-3 pr-4">
                                                <div class="col-9 flex-end align-items-center">
                                                    {{$t('message.VoucherIndex.table.list_voucher.number_voucher')}}:
                                                </div>
                                                <div class="col-1 p-0 d-flex align-items-center">
                                                    <select :value="numberRecord" name="number_records" id="number_records" class="number_records mt-0">
                                                        <option value="15">10</option>
                                                        <option value="50">50</option>
                                                        <option value="100">100</option>
                                                        <option value="150">150</option>
                                                        <option value="200">200</option>
                                                    </select>
                                                </div>
                                                <div class="col-2 align-items-center pr-0">
                                                      <!-- Xuất file lịch sử -->
                                                    <button type="button" class="btn btn-secondary text-white mx-1" @click="exVoucherHistory(entry.historyUse, entry.id)">
                                                        <i class="fa fa-file-export" aria-hidden="true"></i>
                                                        {{$t('message.VoucherIndex.table.list_voucher.button.export')}}
                                                    </button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr class="detail-row bg-white border__footer-detail" v-if="voucherId === entry.id"></tr>
                                </template>
                            </template>
                            <template v-if="entries.length == 0 && !this.loading">
                                <tr>
                                    <td :colspan="fields.length + 1" class="pb-0 bg-light">
                                        <div class="row detail-prd-row mx-0">
                                            <div class="col-12 text-center text-secondary mt-5">
                                                <h3>
                                                    <i class="fa fa-file mr-2 text-secondary"></i>
                                                    <i class="fa fa-exclamation"></i>
                                                </h3>
                                            </div>
                                            <div class="col-12 text-center my-10 text-secondary mb-5">
                                                <!-- <p>{{ $t('message.common.notFound') }}</p> -->
                                                <p>{{ !this.isFilter ? $t('message.common.notData') :  $t('message.common.notFound')}}</p>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <VoucherCreateUpdateModal ref="VoucherCreateUpdateModal" @reloadData="clearFilter()"/>
    <HistoryUseVoucher ref="HistoryUseVoucher"/>
    <HistoryUseVoucherInOrder ref="HistoryUseVoucherInOrder" />
    <ModalMessageVoucher ref="ModalErrorsMessageImport" />
    <ModalRemoveVoucher ref="ModalRemoveVoucher" @reloadData="load()"/>
</AppPermission>
</template>

<script>
    import {$alert, $get, clone, setArray, getSearchQuery, explode, removeVietnameseTones} from "@/lib/utils";
    import Pagination from "@/components/Pagination";
    import TableSortField from "@/components/TableSortField";
    import SelectSearch from "@/components/SelectSearch.vue";
    import SelectField from "@/components/SelectField";
    import InputQuickSearch from "@/components/forms/InputQuickSearch.vue";
    import {$post} from "../../lib/utils";
    import store from "@/store";   
    import emitter from "@/lib/emitter";
    import ModalMessageVoucher from './ModalErrorsMessageVoucher.vue';

    // import modal
    import VoucherCreateUpdateModal from "./VoucherCreateUpdateModal.vue";
    import HistoryUseVoucherInOrder from "./HistoryUseVoucherInOrder.vue";
    import http from '@/services/api';   
    import HistoryUseVoucher from "./HistoryUseVoucher.vue";
    import ModalRemoveVoucher from "./ModalRemoveVoucher.vue";    
    import AppPermission from '@/components/AppPermission.vue';
    import { mapGetters} from "vuex";
    import storeModul from "@/storeModul";
    export default {
        name: "Voucher",
        components: {TableSortField, Pagination, InputQuickSearch, SelectField, SelectSearch, VoucherCreateUpdateModal, ModalMessageVoucher, HistoryUseVoucher, HistoryUseVoucherInOrder, ModalRemoveVoucher, AppPermission},
         mounted() { 
             storeModul.dispatch('settingStore/getStatus', '');
            document.title = this.$t('message.VoucherIndex.document_title');
            const fields = localStorage.getItem('voucherSelectedFields_' + this.user.id);            
            if (fields) {
                this.fields = JSON.parse(fields);
            }
            // this.clearFilter();
            const query = clone(this.$route.query);
            if(query['code'] != null){
                this.filterQuery['coupon_voucher_code_name'] = query['code'];
                this.linkFromNotice = query['detail_code'] ? true : false;
                this.loadDetail();
            }else{
                this.clearFilter();
            }
        },
        data() {
            const query = getSearchQuery();
            const globalConfig = store.state.GlobalConfig;
            return {
                filter: {
                    keyword: query.keyword,
                    status: '',
                    statusEffectTime: '',
                    branches: '',
                    roles: '',
                    username: '',
                    phone: '',
                    email: '',
                    coupon_voucher_code_name: '',
                    voucher_code: '',
                },
                // totalPrice: 0,
                // totalValue: 0,
                filterQuery: {
                    status: null,
                    branches: null,
                },
                checkedAll: false,
                fields: [],
                entries: [],
                // exEntries: [],
                voucherDefault:{
                    filter: null,
                    all: null,
                    search: '',
                    status: null,
                },
                historyUse: [],
                sortData: {
                    field: 'id',
                    direction: 'desc',
                },
                paginate: {
                    currentPage: parseInt(query.page) || 1,
                    lastPage: 1
                },
                optionStatus: [
                    { id: 0, label: this.$t('message.common.all'), value: '' },
                    { id: 1, label: this.$t('message.VoucherIndex.modal.create_or_update.status_active'), value: 1 },
                    { id: 2, label: this.$t('message.VoucherIndex.modal.create_or_update.status_inactive'), value: 0 },
                ],
                numberRecord: 15,
                recordVoucher: 10,
                paginateDetail: {
                    currentPage: 1,
                    lastPage: 1,
                },
                branches: globalConfig.branches,
                roles: globalConfig.roles,
                user: store.state.Auth.user,
                arrayChecked: [],
                loading: false,
                headerActive: 'info',
                voucherId: null,
                title: '',
                content: '',
                type: '',
                isFilter: false,
                linkFromNotice: false,
            }
        },
        methods: {
            async clearFilter() {
                await this.$router.replace({
                    path: '/vouchers/index',
                    query: {}
                });

                Object.keys(this.filter).forEach(key => {
                    this.filter[key] = '';
                });
                Object.keys(this.filterQuery).forEach(key => {
                    this.filterQuery[key] = '';
                });
                await emitter.emit("clearQuickSearch");
                await emitter.emit("clearSelectSearchValue");
                this.filterQuery.status = '';
                this.filterQuery.branches = store.state.CurrentBranch?.id;
                this.arrayChecked = [];
                this.voucherId = null;
                this.numberRecord = 15;
                this.isFilter = false;
                this.linkFromNotice = false;
                this.load();
            },
            async loadDetail(){
                await emitter.emit("clearQuickSearch");
                await emitter.emit("clearSelectSearchValue");
                this.filterQuery.branches = store.state.CurrentBranch?.id;
                this.voucherId = null;
                await this.load();
                this.entries.forEach((entry) => {
                    this.detailVoucher(entry.id, entry);
                });
            },
            getCheckedIDs() {
                return this.entries.filter(e => e.checked).map(e => e.id);
            },
            isCheckedAll(){
                let getChecked = 0;
                this.entries.forEach((entry) => {
                    const found = this.arrayChecked.find(id => id === entry.id);
                    if(found){
                        getChecked += 1;
                        entry.checked = true;
                    }
                });

                if(getChecked > 0 && getChecked == this.entries.length){
                    this.checkedAll = true;
                }else{
                    this.checkedAll = false;
                }
            },
            onCheckedAll(value) {
                setArray(this.entries, {
                    checked: value
                });
                if(value == true){
                    this.entries.forEach(entry => {
                        const found = this.arrayChecked.find(id => id === entry.id);
                        if(!found){
                            this.countChecked(entry.id);
                        }
                    })
                }else{
                    this.entries.forEach(entry => {
                        this.countChecked(entry.id);
                    })
                }
            },
            isExistInArrayChecked(entry, array = []){
                if(array.includes(entry.id)){
                    entry.checked = true;
                    return true;
                }else{
                    entry.checked = false;
                    return false;
                }
            },
            countChecked(id){
                let arr = this.arrayChecked;
                if(arr.includes(id)){
                    arr = arr.filter(item => item !== id)
                }else{
                    arr.push(id)
                }
                this.arrayChecked = arr;
                this.isCheckedAll();
            },
            removeChecked(){
                this.arrayChecked = [];
                this.isCheckedAll();
            },
            async doFilterSearch(v){
                const query = clone(this.filterQuery);
                delete query[v.label];
                if(v.model){
                    query[v.label] = v.model;
                }
                query['page'] = 1;
                this.filterQuery = {...query};
                this.isFilter = true;
                await this.load();
            },
            async doFilter(field, value) {
                const query = clone(this.filterQuery);
                query[field] = value;
                query['page'] = 1;
                this.filterQuery = {...query};
                this.isFilter = true;
                await this.load();
            },
            async pageChange(page) {
                const query = clone(this.filterQuery);
                query['page'] = page;
                this.filterQuery = {...query};
                this.isFilter = true;
                await this.load();
            },
            async onChange(v) {
                this.numberRecord = v.target.value
                let query = clone(this.filterQuery);
                query['page'] = 1;
                this.filterQuery = {...query};
                this.isFilter = true;
                await this.load();
            },
            async changeQuickSearch(object){
                const query = clone(this.filterQuery);
                Object.keys(this.filter).forEach(key => {
                    object.forEach(obj => {
                        Object.keys(obj).forEach(o => {
                            if(key == o && obj[o] != ''){
                                this.filter[key] = obj[o];
                                query[o] = obj[o];
                            }else if(key == o && obj[o] == ''){
                                delete query[o];
                            }
                        });
                    })
                });
                query['page'] = 1;
                this.isFilter = true;
                this.filterQuery = {...query};
                await this.load();
            },
            async load() {
                
                this.loading = true;
                emitter.emit("onLoading");
                const res = await $post('/v1/vouchers/get-data-vouchers', {...this.filterQuery, numberRecord: this.numberRecord, currentLoadPage: 'voucher'});
               
                if (!this.fields || !this.fields.length) {
                    this.fields = res.result.data.fields;
                }
                // this.paginate.currentPage = res.result.data.paginate.currentPage;
                // this.paginate.lastPage = res.result.data.paginate.lastPage;
                // this.paginate.totalRecord = res.result.data.paginate.totalRecord;
                // this.entries = res.result.data.entries; 
                this.entries = res.result.data.data.data;       
                this.paginate.totalRecord = res.result.data.data.total;
                this.paginate.currentPage = res.result.data.data.current_page;
                this.paginate.lastPage = res.result.data.data.last_page;               
                this.isCheckedAll();                 
                
                emitter.emit("offLoading");
                this.loading = false; 
                  
            },
            
            showHide(name,iDown, iUp){
                var dropdownContent = document.getElementById(name);
                dropdownContent.classList.toggle("dp-hide");
                var down = document.getElementById(iDown);
                down.classList.toggle("dp-hide");
                var up = document.getElementById(iUp);
                up.classList.toggle("dp-hide");
            },
            onMouseLeave(){
                emitter.emit("mouseleave");
            },
            detailVoucher(id, entry){
                if (id === this.voucherId) {
                    this.voucherId = null;
                    return;
                } else {
                    this.voucherId = id;
                }
                this.voucherDefault.filter = this.voucherDefault.all = [];
                this.voucherDefault.search = this.$constant.statusCouponVoucherAll;
                this.voucherDefault.status = null;
                if(entry.details){
                    this.voucherDefault.filter = this.voucherDefault.all = entry.details;
                }
                this.headerActive = 'info';
                if(this.linkFromNotice){
                    this.headerActive = 'listVoucher';
                    this.voucherDefault.status = this.$constant.statusCouponVoucherReleased;
                }
                if(entry.historyUse && entry.historyUse.length){
                    entry.historyUse.sort(function(a, b) { return new Date(b.date) - new Date(a.date) });
                }
                this.linkFromNotice = false;                
                this.recordVoucher = 10;
                this.setPaginate();
            },
            onInputFilterCouponDetail(){
                this.paginateDetail.currentPage = 1;
                if(this.voucherDefault.search?.length == 0 || !this.voucherDefault.search){
                    this.voucherDefault.filter = this.voucherDefault.all;
                    this.filterCoupon(this.voucherDefault.filter);
                    this.setPaginate();
                    return;
                }
                let arr = [];
                for (let index = 0; index < this.voucherDefault.all.length; index++) {
                    if (this.voucherDefault.all[index] != null && removeVietnameseTones(this.voucherDefault.all[index].code.toLowerCase()).indexOf(removeVietnameseTones(this.voucherDefault.search.toLowerCase())) > -1){
                        arr.push(this.voucherDefault.all[index]);
                    }
                }
                this.filterCoupon(arr);
                this.setPaginate();
            },
            setPaginate(){
                this.paginateDetail.currentPage = 1;
                this.paginateDetail.lastPage = Math.ceil(this.voucherDefault.filter.length / this.recordVoucher);
                this.paginateDetail.totalRecord = this.voucherDefault.filter.length;
            },
            changeRecordVoucher(v){
                this.recordVoucher = v.target.value;
                this.setPaginate();
            },
            showContent(name) {
                this.headerActive = name;
            },
            createVoucher(){
                this.$refs.VoucherCreateUpdateModal.show();
            },
            showHistoryDetail(id){
                this.$refs.HistoryUseVoucher.show(id);
            },
            showHistoryDetailInOrder (id) {
                this.$refs.HistoryUseVoucherInOrder.show(id);
            },
            doFilterSearchCouponList(v){
                this.voucherDefault.status = v.model ?? null;
                this.onInputFilterCouponDetail();
            },
            filterCoupon(arr = []){
                if(this.voucherDefault.status == this.$constant.statusCouponVoucherAll){
                    this.voucherDefault.filter = arr;
                }else{
                    let array = [];
                    arr.forEach(el => {
                        if(el.statusOrigin == this.voucherDefault.status){
                            array.push(el);
                        }
                    });
                    this.voucherDefault.filter = array;
                }
            },
            update (entry) {
                this.$refs.VoucherCreateUpdateModal.show(entry);
            },
            coppy(entry, type = null) {
                this.$refs.VoucherCreateUpdateModal.show(entry, type);
            },
            RemoveVoucher(id, name){
                this.$refs.ModalRemoveVoucher.show(id, name);
            },
            // Xuất file lịch sử sử dụng
            async exVoucherHistory(historyUse, id) {
                this.filterQuery.export = true;
                await this.load();
                // let id = this.getOSByID(historyUse);  
                const res = await http.download('/v1/vouchers/export-voucher-history', {
                    id: id,
                    historyUse: historyUse
                });
                const fileName = res.headers['content-disposition'].split('filename=')[1];
                var _blob = new Blob([res.data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                });
                var link = document.createElement("a");
                link.href = window.URL.createObjectURL(_blob);
                link.setAttribute('download', fileName);
                link.click();  
                this.filterQuery.export = false;          
            },  
            async exVoucherCode(voucher) {                  
                this.filterQuery.export = true;
                await this.load();
                let id = this.getOSByID(voucher);
                const res = await http.download('/v1/vouchers/export-voucher-code', {id: id});
                const fileName = res.headers['content-disposition'].split('filename=')[1];
                var _blob = new Blob([res.data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                });
                var link = document.createElement("a");
                link.href = window.URL.createObjectURL(_blob);
                link.setAttribute('download', fileName);
                link.click();  
                this.filterQuery.export = false;          
            },            
            getOSByID(entries){                
                let id = [];
                entries.forEach(item => {
                    id.push(item.id);
                });                
                return id;
            },  

        }, // End method
        computed: {
            ...mapGetters({
                isUseVoucher: 'settingStore/isUseVoucher',
            })
        },
    }
</script>
<style scoped>
#number_records {
  margin-top: 11px;
}
.container input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.text-title {
  line-height: 52px;
}

#typeCard {
  margin-top: -8px;
}
#fa_edit {
    position: absolute;
    left:20px;
    top:4px;
    color:#a7a2a2;
}
#noteRO {
    border-left: 1px solid #ccc;
}
.noteStyle {
    width: 100% !important;
    height: 120px !important;
    border: 0;
    border-radius: 0;
}
.noteStyle:focus {
    outline: none !important;
    border-color: none;
    box-shadow: 0 none;
}
.fa-search{
    position: absolute;
    right: 0;
    top: 40%;
}
.table-header th{
    height: 40px !important;
}
.table-header-search, .table-header-search:hover{
    height: 50px !important;
    background-color: #FEFCED !important;
}
/* .cell-check{
    width: 16px;
    min-width: 16px;
    max-width: 16px;
}

.cell-release-coupon{
    width: 190px;
    min-width: 190px;
    max-width: 190px;
}
.cell-auto{
    min-width: 160px;
}
 */
.cell-code-coupon{
    width: 232px;
    min-width: 232px;
    max-width: 232px;
}
.cell-status-coupon{
    width: 150px;
    min-width: 150px;
    max-width: 150px;
}
</style>

