<template>
    <AppPermission :permission="this.$permissions.suppliers.data ? this.$permissions.suppliers.data : 'Suppliers.data'">
      <div >
        <div class="container my-4">
          <div class="row m-0 p-0">
            <div class="mainLeft">
            <!-- <div class="col-md-2" id="col2"> -->
              <div class="main-title">
                <span>{{ $t('message.supplierIndex.title') }}</span>
              </div>
              <div>
                <div class="card-header mx-0 px-0" id="typeHeader">
                  <div class="row mx-2">
                    <div class="col-8 text-title">
                      <label>{{ $t('message.common.numberRecord') }}:</label>
                    </div>
                    <div class="col-4">
                      <select :value="numberRecord" name="number_records" id="number_records" class="number_records" @change="onChange($event)">
                        <option value="15">15</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Start group customer -->
              <div class="card" id="typeCard">
                <div class="card-header mx-0 px-0 pb-0" id="typeHeader">
                  <div class="row mx-2">
                    <div class="col-10">
                      <label class="typeTitle">{{ $t('message.supplierIndex.supplierGroup') }}</label>
                    </div>                    
                    <div class="col-1 pl-0 ml-0">
                      <AppPermission :permission="this.$permissions.supplierGroups.create ? this.$permissions.supplierGroups.create : 'SupplierGroups.data'">
                          <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showModalSupplierGroups">
                            <i class="fa fa-plus-circle fa-md text-secondary"></i>
                          </a>
                      </AppPermission>
                    </div>
                    
                  </div>
                  <!-- Tìm kiếm -->
                  <div class="row mx-2 my-2 py-2 ">
                    <div class="col-12">
                        <div class="select-option dropdown-toggle h-100" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <template v-if="this.filterSupplierGroups?.length === 0">
                            <span>
                                {{ $t('message.supplierIndex.allGroup') }}
                            </span>
                          </template>
                          <template v-else v-for="supplierGroup in this.supplierGroups" :key="supplierGroup.id">
                            <span v-if="supplierGroup.id == this.filterSupplierGroups">{{supplierGroup.name}}</span>
                          </template>
                        </div>
                        <div class="dropdown-menu dropdownSupplier" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item" href="javascript:;" @click="handleCheckSupplierGroups()">
                                {{ $t('message.supplierIndex.allGroup') }}
                            </a>
                            <a class="dropdown-item" href="javascript:;" v-for="supplierGroup in this.supplierGroups" :key="supplierGroup.id" :value="supplierGroup.id" @click="handleCheckSupplierGroups(supplierGroup.id)">
                              <span :class="supplierGroup.id == this.filterSupplierGroups ? 'text-bold' : ''">{{supplierGroup.name}}</span><i class="fa fa-pencil-alt ml-2 text-secondary" @click.stop="showModalEditSupplierGroups(supplierGroup.id)"></i>
                            </a>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- End group customer -->

              <!-- Start tổng mua -->
              <div class="card mt-4" id="typeCard">
                <div class="card-header mx-0 px-0" id="typeHeader">
                    <div class="row mx-2">
                        <div class="col-9">
                            <label class="typeTitle">{{ $t('message.supplierIndex.totalBuy') }}</label>
                        </div>
                        <div class="col-1">
                         
                            <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('timeBody', 'timeDown', 'timeUp')">
                                <i class="fa fa-chevron-down dp-hide" id="timeDown"></i>
                                <i class="fa fa-chevron-up" id="timeUp"></i>
                            </a>
                        </div>
                    </div>
                </div>
                  <div class="card-body my-0 pt-0 pb-2" id="feeShippingBody">
                        <div class="row">
                            <div class="col-12 d-flex align-items-center">
                                <span>{{ $t('message.TransporterIndex.fee_from') }}</span>
                                <InputNumber v-model="filterQuery.from" 
                                :placeholder="$t('message.TransporterIndex.placeholder.value')" 
                                class="mb-0 ml-1" :cardTransaction="true" @change="load()"/>
                            </div>
                        </div>
                        <div class="row mt-2 mb-1">
                            <div class="col-12 d-flex align-items-center">
                                <span>{{ $t('message.TransporterIndex.fee_to') }}</span>
                                <InputNumber v-model="filterQuery.to" 
                                :placeholder="$t('message.TransporterIndex.placeholder.value')" 
                                class="mb-0 ml-1" :cardTransaction="true" @change="load()"/>
                            </div>
                        </div>
                        <SelectDateTime :label="'time'" @update:modelValue="doFilterTime"/>
                    </div>
              </div>
              <!-- End tổng mua -->

              <!-- Start nợ hiện tại -->
              <!-- <div class="card mt-4" id="typeCard">
                <div class="card-header mx-0 px-0" id="typeHeader">
                  <div class="row mx-2">
                    <div class="col-9">
                      <label class="typeTitle">Nợ hiện tại</label>
                    </div>
                    <div class="col-1">
                      <a href="javascript:void(0);" class="text-dark" id="linkIcon"
                        @click="showHide('currentDebt', 'cdDown', 'cdUp')">
                        <i class="fa fa-chevron-down dp-hide" id="cdDown"></i>
                        <i class="fa fa-chevron-up" id="cdUp"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="card-body my-0 py-2" id="currentDebt">
                  <div class="row mb-2">
                    <div class="col-12">
                      <div class="prd-search-box1">
                        <InputDatePicker placeholder="Nợ hiện tại" class="w-100 mb-0 custom-input" @input="doFilter('created_at', filter.created_at)"
                                              v-model="filter.created_at"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
              <!-- End nợ hiện tại -->

              <!-- Start khu vực giao hàng -->
              <div class="card mt-4" id="typeCard">
                <div class="card-header mx-0 px-0" id="typeHeader">
                  <div class="row mx-2">
                    <div class="col-9">
                      <label class="typeTitle">{{ $t('message.supplierIndex.status') }}</label>
                    </div>
                    <div class="col-1">
                      <a href="javascript:void(0);" class="text-dark" id="linkIcon"
                        @click="showHide('status', 'sttDown', 'sttUp')">
                        <i class="fa fa-chevron-down dp-hide" id="sttDown"></i>
                        <i class="fa fa-chevron-up" id="sttUp"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="card-body my-0 py-2" id="status">
                  <div class="row mb-2">
                    <div class="col-12">
                      <div class="prd-search-box1">
                        <div class="select-option dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <span v-if="this.filterStatus == null">{{ $t('message.supplierIndex.all') }}</span>
                          <span v-else-if="this.filterStatus == 1">{{ $t('message.supplierIndex.active') }}</span>
                          <span v-else-if="this.filterStatus == 2">{{ $t('message.supplierIndex.inActive') }}</span>
                        </div>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item" href="javascript:;" @click="doFilter('status', null)">{{ $t('message.supplierIndex.all') }}</a>
                            <a class="dropdown-item" href="javascript:;" @click="doFilter('status', 1)">{{ $t('message.supplierIndex.active') }}</a>
                            <a class="dropdown-item" href="javascript:;" @click="doFilter('status', 2)">{{ $t('message.supplierIndex.inActive') }}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- End khu vực giao hàng -->
            </div>

            <div class="mainRight">
            <!-- <div class="col-md-10" id="col10"> -->
              <div class="sticky-top">
                <div class="row">
                  <div class="col-lg-5 mb-1">
                    <div class="search-box">
                      <i class="fa fa-search mr-2"></i>
                      <input type="text" class="search-input"
                            @keydown.enter="doFilter('keyword', filter.keyword)" v-model="filter.keyword"
                            :placeholder="$t('message.supplierIndex.searchSupplier')" />
                    </div>
                  </div>
                  <div class="col-lg-7 d-flex justify-content-end">
                    <button type="button" class="btn btn-default"  @click="clearFilter()">
                        <i class="fa fa-filter"></i>
                        {{ $t('message.button.removeFilter') }}
                    </button>
                    <AppPermission :permission="this.$permissions.suppliers.create ? this.$permissions.suppliers.create : 'Suppliers.create'">
                      <button class="btn btn-save" type="button" id="dropdownMenuButton"
                              style="padding:7px"
                              @click="showModalAddInfo"
                              aria-expanded="false">
                        <i class="fa fa-plus px-2"></i>
                        {{$t('message.supplierIndex.button.addSupplier')}}
                      </button>
                    </AppPermission>

                    <AppPermission :permission="this.$permissions.suppliers.import">
                        <button class="btn btn-save" type="button" id="dropdownMenuButton"
                                style="padding:7px"
                                @click="showModalUploadFileExcel"
                                aria-expanded="false">
                            <i class="fas fa-file-import px-2"></i>
                            {{ $t('message.button.import.name') }}
                        </button>
                    </AppPermission>
                    <AppPermission :permission="this.$permissions.suppliers.export">
                        <!-- <export-excel :fetch="exportExcel" :fields="excelData.fields" :name="excelData.fileName"> -->
                            <button type="button" class="btn btn-save" style="padding-bottom: 0.5rem" @click="selectOptionExport('exportExcel')">
                                <i class='fas fa-file-export'></i>
                                {{ $t('message.button.export.name') }}
                            </button>
                        <!-- </export-excel> -->
                    </AppPermission>
                    <div id="loc" v-on:mouseleave="onMouseLeave">
                      <SelectField :fields="fields" label="suppliers"></SelectField>
                    </div>
                  </div>
                </div>
                <div>
                  <Pagination :value="paginate" :record="this.numberRecord" @change="pageChange" />
                </div>
                <div class="row my-2"></div>
                <div class="row mx-0 my-2" v-if="getDataChecked(this.fields) > 0 && entries.length > 0" id="divTable">
                  <table class="table table-index table-striped lstProduct" >
                    <thead>
                    <tr>
                      <th scope="col" class="checkbox-table"><input v-model="checkedAll" @change="onCheckedAll(checkedAll)" type="checkbox"/></th>
                      <template v-for="field in fields" :key="field.field">
                        <th scope="col" v-if="field.selected && field.field == 'name'"
                            :class="field.class" style="vertical-align: middle !important;">
                          <TableSortField :current="sortData.field" :no-sort="!field.isColumn" @onsort="sort"
                                          :name="field.name" :field="field.field" />
                        </th>
                        <th scope="col" v-else-if="field.selected" style="vertical-align: middle !important;">
                          <TableSortField :current="sortData.field" :no-sort="!field.isColumn" @onsort="sort"
                                          :class="field.class" :name="field.name" :field="field.field" :style="field.style"  />
                        </th>
                      </template>
                    </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="prdChecked ow-break-word py-0 bg-light-warning">
                        </td>
                        <template v-for="field in fields" :key="field.field">
                          <td class="ow-break-word py-0 bg-light-warning" v-if="field.selected" style="vertical-align:middle !important;">  
                            <div v-if="field.field === 'sumBuy'" class="cell-auto py-2" v-text="n(this.sumBuyAll)"></div>
                            <div v-else class="cell-auto text-center"></div>
                          </td>
                        </template>
                      </tr>
                    <template v-for="(entry, index) in entries" :key="entry.id" >
                      <tr class="master-row" :style="index % 2 ? null : 'background-color: white'" :class="entry.id === this.supplierIdDetail ? 'backgroup__detail border__master' :''" :id="'row_' + (index + 1)">
                        <td> <input v-model="entry.checked" type="checkbox"  @change.stop="countChecked('prdChecked', entries, entry.id)" class="prdChecked"
                                    :id="entry.id" :name="entry.name" :value="entry.code"/></td>
                        <template v-for="field in fields" :key="field.field">
                          <td class="ow-break-word py-0" v-if="field.selected && field.field !== 'supplierGroupName'" style="vertical-align:middle !important;" :style="'min-width:110px'">
                            <a :href="'#row_' + index" class="description text-dark tagA" @click="detailSupplier(entry.id)">
                              <div v-if="field.field==='code'" :style="field.style" class="description">
                                <span class="mr-1" v-text="entry.code" :style="field.style"></span>
                              </div>
                              <div v-else-if="field.field==='sumBuy'" v-text="n(entry.sumBuy)"></div>
                              <div v-else-if="field.field==='name'" :style="field.style" class="description"><span v-text="entry.name"></span></div>
                              <div v-else-if="field.field==='createdAt'" :style="field.style" v-text="renderField(entry, field.field, field)  ? new Date(entry.createdAt).toLocaleDateString('en-GB') : '' "></div>
                              <div v-else-if="field.field==='note'" :style="field.style" class="description"><span v-text="entry.note"></span></div>
                              <div v-else-if="field.field==='company'" :style="field.style" class="description"><span v-text="entry.company"></span></div>
                              <div v-else-if="field.field==='email'" :style="field.style" class="description"><span v-text="entry.email"></span></div>
                              <div v-else-if="field.field==='countSku'" :style="field.style" class="description"><span v-text="entry.countSku"></span></div>
                              <div v-else-if="field.field==='amountImport'" :style="field.style" class="description"><span>{{ $filters.formatPrice(entry.amountImport) }}</span></div>
                              <div v-else-if="field.field==='openingFee'" :style="field.style" class="description"><span>{{ $filters.formatPrice(entry.openingFee) }}</span></div>
                              <div v-else-if="field.field==='paymentDiscount'" :style="field.style" class="description"><span>{{ $filters.formatPrice(entry.paymentDiscount) }}</span></div>
                              <div v-else-if="field.field==='address' || field.field==='deliveryArea'" :style="field.style" class="description" v-text="renderField(entry, field.field, field)" :title="renderField(entry, field.field, field)"></div>
                              <div v-else v-text="renderField(entry, field.field, field)" :title="renderField(entry, field.field, field)" :style="field.style" :class="field.class">
                              </div>
                            </a>
                          </td>
                          <td class="ow-break-word" v-if="field.selected && field.field==='supplierGroupName'" style="vertical-align:middle !important;" :style="'min-width:110px'">
                              <div v-if="field.field==='supplierGroupName' && entry.groups && entry.groups.length" class="pre-formatted" style="white-space: pre">
                                  <select class="sltUnit" style="min-width: 160px;">
                                      <!-- <option :key="n" v-for="n in entry.groups" :value="n.id" v-text="n.name"></option> -->
                                      <option :key="n" v-for="n in entry.supplierGroupName" :value="n" v-text="n"></option>
                                  </select>
                                  
                              </div>
                          </td>
                        </template>
                      </tr>
                      <tr v-if="supplierIdDetail === entry.id" :class="entry.id === supplierIdDetail ? 'backgroup__detail border__header-detail' : ''">
                        <td class="no-boder"></td>
                        <td :colspan="fields.length" class="no-boder">
                            <div class="row detail-prd-row">
                                <div class="col-2 mt-2" @click="showContent(this.$constant.supplier.values.info, entry)">
                                    <span :class="[ headerActive === 'info' ? 'backgroup__detail-active' : '', 'head-detail']">
                                       {{$t('message.supplierIndex.tabs.info')}}
                                    </span>
                                </div>
                                <!-- <div class="col-2 mt-2" @click="showContent(this.$constant.supplier.values.paymentForSupplier, entry)">
                                    <span :class="[ headerActive === this.$constant.supplier.values.paymentForSupplier ? 'backgroup__detail-active' : '', 'head-detail']">
                                     {{$t('message.supplierIndex.tabs.paymentForSupplier')}}
                                    </span>
                                </div> -->
                                <!-- Lịch sử đặt hàng nhập -->
                                <div class="col-2 mt-2" @click="showContent(this.$constant.supplier.values.historyOrder, entry)" v-if="entry.order_for_supplier.length > 0">
                                    <span :class="[headerActive === this.$constant.supplier.values.historyOrder ? 'backgroup__detail-active' : '', 'head-detail']">
                                        {{$t('message.supplierIndex.tabs.historyOrder')}}
                                    </span>
                                </div>
                                <!-- Lịch sử nhập/trả hàng -->
                                <div class="col-2 mt-2" v-if="entry.import_for_suppliers && entry.import_for_suppliers.length" @click="showContent(this.$constant.supplier.values.historyImport, entry)">
                                    <span :class="[headerActive === this.$constant.supplier.values.historyImport ? 'backgroup__detail-active' : '', 'head-detail']">
                                        {{$t('message.supplierIndex.tabs.historyPurchaseReturn')}}
                                    </span>
                                </div>
                            </div>
                          </td>
                      </tr>
                      <!-- Chi tiết khách hàng -->
                      <tr class="detail-row bg-white border__body-detail border__footer-detail" v-if="supplierIdDetail === entry.id && this.headerActive ==  this.$constant.supplier.values.info">
                        <th class="border-top-0">
                        </th>
                        <td :colspan="getDataChecked(this.fields)" class="border-top-0">
                          <div class="mt-2 pb-4">
                            <div class="row detail-prd-row mt-2">
                              <div class="col-4">
                                <div class="row mx-1">
                                  <div class="col-12 mt-2 py-2 border-bottom-1">
                                    <div class="row">
                                      <div class="col-6">
                                        {{$t('message.supplierIndex.code')}}:
                                      </div>
                                      <div class="col-6 description">
                                        {{entry.code}}
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-12 mt-2 py-2 border-bottom-1">
                                    <div class="row">
                                      <div class="col-6">
                                        {{$t('message.supplierIndex.name')}}:
                                      </div>
                                      <div class="col-6 ow-break-word">
                                        {{entry.name}}
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-12 mt-2 py-2 border-bottom-1">
                                    <div class="row">
                                      <div class="col-6">
                                        {{$t('message.supplierIndex.address')}}:
                                      </div>
                                      <div class="col-6 ow-break-word">
                                        <div v-text="entry.address"></div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-12 mt-2 py-2 border-bottom-1">
                                    <div class="row">
                                      <div class="col-6">
                                        {{$t('message.supplierIndex.deliveryArea')}}:
                                      </div>
                                      <div class="col-6" v-if="entry.deliveryArea">
                                        {{entry.deliveryArea ? entry.deliveryArea : ''}}
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-12 mt-2 py-2 border-bottom-1">
                                    <div class="row">
                                      <div class="col-6">
                                        {{$t('message.supplierIndex.ward')}}:
                                      </div>
                                      <div class="col-6" v-if="entry.wardId">
                                        {{entry.wardId? entry.wardId : '' }}
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-12 mt-2 py-2 border-bottom-1">
                                    <div class="row">
                                      <div class="col-6">
                                        {{$t('message.supplierIndex.phone')}}:
                                      </div>
                                      <div class="col-6">
                                        {{entry.contactNumber? entry.contactNumber : '' }}
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-12 mt-2 py-2 border-bottom-1">
                                    <div class="row">
                                      <div class="col-6">
                                        {{$t('message.supplierIndex.email')}}:
                                      </div>
                                      <div class="col-6 ow-break-word">
                                        {{entry.email? entry.email : '' }}
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-12 mt-2 py-2 border-bottom-1">
                                      <div class="row">
                                          <div class="col-6">
                                            {{$t('message.supplierIndex.accountNumber')}}:
                                          </div>
                                          <div class="col-6 ow-break-word" v-text="entry.accountNumber"></div>
                                      </div>
                                  </div>
                                  <div class="col-12 mt-2 py-2 border-bottom-1">
                                      <div class="row">
                                          <div class="col-6">
                                            {{$t('message.supplierIndex.accountOwner')}}:
                                          </div>
                                          <div class="col-6 ow-break-word" v-text="entry.accountName"></div>
                                      </div>
                                  </div>
                                  <div class="col-12 mt-2 py-2 border-bottom-1">
                                      <div class="row">
                                          <div class="col-6">
                                            {{$t('message.supplierIndex.bankName')}}:
                                          </div>
                                          <div class="col-6 ow-break-word" v-text="entry.bankName"></div>
                                      </div>
                                  </div>
                                  <div class="col-12 mt-2 py-2 border-bottom-1">
                                      <div class="row">
                                          <div class="col-6">
                                            {{$t('message.supplierIndex.bankAddress')}}:
                                          </div>
                                          <div class="col-6 ow-break-word" v-text="entry.bankAddress"></div>
                                      </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-4 ">
                                <div class="row mx-1">
                                    <div class="col-12 mt-2 py-2 border-bottom-1">
                                        <div class="row">
                                        <div class="col-6">
                                            {{$t('message.supplierIndex.company')}}:
                                        </div>
                                        <div class="col-6 ow-break-word">
                                            {{entry.company? entry.company : '' }}
                                        </div>
                                        </div>
                                    </div>
                                    <div class="col-12 mt-2 py-2 border-bottom-1">
                                        <div class="row">
                                        <div class="col-6">
                                            {{$t('message.supplierIndex.taxCode')}}:
                                        </div>
                                        <div class="col-6 country">
                                            {{entry.taxCode? entry.taxCode : '' }}
                                        </div>
                                        </div>
                                    </div>
                                    <div class="col-12 mt-2 py-2 border-bottom-1">
                                        <div class="row ">
                                        <div class="col-6">
                                            {{$t('message.supplierIndex.supplierGroup')}}:
                                        </div>
                                        <div class="col-6 ow-break-word">
                                            <template :key="n" v-for="(n,index) in entry.supplierGroupName">                                          
                                                <span v-if="index == entry.supplierGroupName.length - 1" v-text="n"></span>
                                                <span v-else v-text="n + ','"></span>
                                            </template>
                                        </div>
                                        </div>
                                    </div>
                                    <div class="col-12 mt-2 py-2 border-bottom-1">
                                        <div class="row">
                                        <div class="col-6 ">
                                            {{$t('message.supplierIndex.createdBy')}}:
                                        </div>
                                        <div class="col-6">
                                            {{entry.createdBy? entry.createdBy : '' }}
                                        </div>
                                        </div>
                                    </div>
                                    <div class="col-12 mt-2 py-2 border-bottom-1">
                                        <div class="row">
                                        <div class="col-6">
                                            {{$t('message.supplierIndex.createdAt')}}:
                                        </div>
                                        <div class="col-6">
                                            {{entry.createdAt? new Date(entry.createdAt).toLocaleDateString('en-GB')  : '' }}
                                        </div>
                                        </div>
                                    </div>
                                    <div class="col-12 mt-2 py-2 border-bottom-1">
                                        <div class="row">
                                            <div class="col-6">
                                                {{$t('message.supplierIndex.debtDate')}}:
                                            </div>
                                            <div class="col-6" v-text="entry.numberDebtDate ? n(entry.numberDebtDate) + ' ' + $t('message.common.date') : (entry.recurringPaymentDate ? (entry.recurringPaymentDate) : null)"></div>
                                        </div>
                                    </div>
                                    <div class="col-12 mt-2 py-2 border-bottom-1">
                                        <div class="row">
                                            <div class="col-6">
                                                {{$t('message.supplierIndex.employee')}}:
                                            </div>
                                            <div class="col-6" v-text="entry.user ? entry.user.name : null"></div>
                                        </div>
                                    </div>
                                    <div class="col-12 mt-2 py-2 border-bottom-1">
                                        <div class="row">
                                            <div class="col-6">
                                                {{$t('message.supplierIndex.numberSKU')}}:
                                            </div>
                                            <div class="col-6" v-text="entry.countSku"></div>
                                        </div>
                                    </div>
                                    <div class="col-12 mt-2 py-2 border-bottom-1">
                                        <div class="row">
                                            <div class="col-6">
                                                {{$t('message.supplierIndex.amountImport')}}:
                                            </div>
                                            <div class="col-6">
                                                {{ $filters.formatPrice(entry.amountImport) }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 mt-2 py-2 border-bottom-1">
                                        <div class="row">
                                            <div class="col-6">
                                                {{$t('message.supplierIndex.openingFee')}}:
                                            </div>
                                            <div class="col-6">
                                                {{ $filters.formatPrice(entry.openingFee) }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 mt-2 py-2 border-bottom-1">
                                        <div class="row">
                                            <div class="col-6">
                                                {{$t('message.supplierIndex.paymentDiscount')}}:
                                            </div>
                                            <div class="col-6">
                                                {{ $filters.formatPrice(entry.paymentDiscount) }}
                                            </div>
                                        </div>
                                    </div>
                                
                                </div>
                              </div>

                                <div class="col-4"  style="border-left: 1px solid lightgray">
                                  <span class="note-supplier text-gray" v-if="entry.note"><i class="fa fa-pencil-alt mr-1"></i>{{entry.note}}</span>
                                  <span class="note-supplier text-gray" v-else><i class="fa fa-pencil-alt mr-1"></i>{{$t('message.supplierIndex.note')}}...</span>
                                </div>

                            </div>

                            <div class="row detail-prd-row flex-end mt-4 pr-5">
                              <AppPermission :permission="this.$permissions.suppliers.update ? this.$permissions.suppliers.update : 'Suppliers.update'">
                                  <button type="button" class="btn btn-save mx-1" @click="showModalSuppliersEditById(entry.id)">
                                    <i class="fa fa-check"></i>
                                    {{$t('message.supplierIndex.button.update')}}
                                  </button>
                              </AppPermission>
                              <AppPermission :permission="this.$permissions.suppliers.inActive ? this.$permissions.suppliers.inActive : 'Suppliers.inActive' ">
                                  <button v-if="entry.deletedAt == null &&  entry.statusOrigin == this.$constant.active" type="button" class="btn btn-danger mx-1" @click="showModalChangeStatusSupplier(entry.id, 'stop')">
                                    <i class="fa fa-lock"></i>
                                    {{$t('message.supplierIndex.inActive')}}
                                  </button>                              
                                  <button v-if="entry.deletedAt == null &&  entry.statusOrigin == this.$constant.statusStopSupplier" type="button" class="btn btn-save mx-1" @click="showModalChangeStatusSupplier(entry.id, 'reActive')">
                                    <i class="fa fa-unlock"></i>
                                    {{$t('message.supplierIndex.reActive')}}
                                  </button>
                              </AppPermission>
                              <AppPermission :permission="this.$permissions.suppliers.remove?? 'Suppliers.remove'">
                              <button type="button" class="btn btn-danger mx-1" @click="showModalSuppliersRemove(entry.id)">
                                <i class="fa fa-trash-alt"></i>
                                {{$t('message.supplierIndex.button.remove')}}
                              </button>
                              </AppPermission>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <!-- Lịch sử đặt hàng nhập -->
                      <tr class="detail-row bg-white border__body-detail" v-if="supplierIdDetail === entry.id && this.headerActive ==  this.$constant.supplier.values.historyOrder">                        
                        <td :colspan="fields.length" class="border-top-0">
                            <div class="mt-2 pb-4">                                                                
                                <div class="detail-prd-row mt-2 pl-3">
                                    <table class="w-100" style="max-width:97%;">
                                        <thead>
                                            <tr class="backgroup__detail table-header border-1">                                                                    
                                                <th scope="col" class="checkbox-table">{{$t('message.supplierIndex.table.code')}}</th>
                                                <th scope="col" class="checkbox-table">{{$t('message.supplierIndex.table.time')}}</th>
                                                <th scope="col" class="checkbox-table">{{$t('message.supplierIndex.table.createBy')}}</th>
                                                <th scope="col" class="checkbox-table">{{$t('message.supplierIndex.table.branch')}}</th>
                                                <th scope="col" class="checkbox-table">{{$t('message.supplierIndex.table.total')}}</th>
                                                <th scope="col" class="checkbox-table">{{$t('message.supplierIndex.table.status')}}</th>
                                            </tr>
                                        </thead>
                                        <tbody> 
                                              <!-- <tr class="table-header border-1" v-for="(item, index) in entry.order_for_supplier" :key="index">                                                  
                                                    <td class="col-1 text-center"  @click="showDetailStockCard(item.id, this.$constant.stockCardOrderForSupplier)">
                                                        <span class="text-primary">{{ item.code }}</span>
                                                    </td>
                                                    <td>{{ item.orderDate ? dt(item.orderDate) : null }}</td>
                                                    <td>{{ item.created_by.name }}</td>
                                                    <td>{{ item.branch.name }}</td>
                                                    <td>{{ n(item.totalPrice) }}</td>
                                                    <td>{{ item.statusName }}</td>                                                                        
                                                </tr> -->
                                            <template v-if="this.dataDetail?.length > 0">
                                              <tr v-for="(item, i) in this.dataDetail" :key="i" class="table-header border">
                                                  <template v-if="i >= (this.paginateDetail.currentPage - 1)*10 && i < this.paginateDetail.currentPage*10">
                                                    <td class="col-1 text-center"  @click="showDetailStockCard(item.id, this.$constant.stockCardOrderForSupplier)">
                                                        <span class="text-primary">{{ item.code }}</span>
                                                    </td>
                                                    <td>{{ item.orderDate ? dt(item.orderDate) : null }}</td>
                                                    <td>{{ item.created_by.name }}</td>
                                                    <td>{{ item.branch.name }}</td>
                                                    <td>{{ n(item.totalPrice) }}</td>
                                                    <td>{{ item.statusName }}</td>
                                                  </template>
                                              </tr>
                                          </template>
                                        </tbody>
                                    </table>
                                    <Pagination :value="paginateDetail" :record="this.numberRecordDetail" v-if="this.paginateDetail.totalRecord >= this.numberRecordDetail"/>
                                </div>
                            </div>
                            <div class="row detail-prd-row flex-end pr-3">
                               <AppPermission :permission="this.$permissions.suppliers.export">
                                  <button type="button" class="btn btn-secondary mx-1" @click.stop="exHistoryOrderSupplier(entry.id)">
                                      <i class="fa fa-file-export pr-1"></i>
                                      {{$t('message.common.export')}}
                                  </button>
                               </AppPermission>
                            </div>
                        </td>
                    </tr>

                    <!-- Lịch sử nhập hàng/ trả hàng -->
                    <tr class="detail-row bg-white border__body-detail" v-if="supplierIdDetail === entry.id && this.headerActive ==  this.$constant.supplier.values.historyImport">                        
                        <td :colspan="fields.length" class="border-top-0">
                            <div class="mt-2 pb-4">                                                                
                                <div class="detail-prd-row mt-2 pl-3">
                                    <table class="w-100" style="max-width:97%;">
                                        <thead>
                                            <tr class="backgroup__detail table-header border-1">                                                                    
                                                <th scope="col" class="checkbox-table">{{$t('message.supplierIndex.table.code')}}</th>
                                                <th scope="col" class="checkbox-table">{{$t('message.supplierIndex.table.time')}}</th>
                                                <th scope="col" class="checkbox-table">{{$t('message.supplierIndex.table.createBy')}}</th>
                                                <th scope="col" class="checkbox-table">{{$t('message.supplierIndex.table.branch')}}</th>
                                                <th scope="col" class="checkbox-table">{{$t('message.supplierIndex.table.total')}}</th>
                                                <th scope="col" class="checkbox-table">{{$t('message.supplierIndex.table.status')}}</th>                                                                    
                                            </tr>
                                        </thead>
                                        <tbody> 
                                              <!-- <tr class="table-header border-1" v-for="(item, index) in entry.import_for_suppliers" :key="index">                                                  
                                                    <td class="col-1 text-center">
                                                        <span class="text-primary" @click="showDetail(item.id , 'importForSupplier')">{{ item.code }}</span>                                                                             
                                                    </td>
                                                    <td>{{ item.importDate ? dt(item.importDate) : null }}</td>
                                                    <td>{{ item.users.name }}</td>
                                                    <td>{{ item.branch ? item.branch.name : null }}</td>
                                                    <td>{{ n(item.totalPrice) }}</td>
                                                    <td>{{ item.statusName }}</td>                                                                        
                                                </tr>

                                                <tr class="table-header border-1" v-for="(item, index) in entry.return_import_for_supplier" :key="index">                                                  
                                                    <td class="col-1 text-center" >
                                                      <span class="text-primary" @click="showDetail(item.id , 'returnForSupplier')">{{ item.code }}</span>                                                                          
                                                    </td>
                                                    <td>{{ item.returnDate ? dt(item.returnDate) : null }}</td>
                                                    <td>{{ item.created_by.name }}</td>
                                                    <td>{{ item.import_for_supplier.branch ? item.import_for_supplier.branch.name : null }}</td>
                                                    <td>{{ n(item.totalPrice) }}</td>
                                                    <td>{{ item.statusName }}</td>                                                                        
                                                </tr> -->
                                            <template v-if="this.dataDetail?.length > 0">
                                              <tr v-for="(item, i) in this.dataDetail" :key="i" class="table-header border">
                                                  <template v-if="i >= (this.paginateDetail.currentPage - 1)*10 && i < this.paginateDetail.currentPage*10">
                                                    <td class="col-1 text-center">
                                                        <span class="text-primary" @click="showDetail(item.id , (item.isImportForSupplier ? 'importForSupplier' : 'returnForSupplier'))">{{ item.code }}</span>                                                                             
                                                    </td>
                                                    <td>{{ item.isImportForSupplier && item.importDate ? dt(item.importDate) : (item.returnDate ? dt(item.returnDate) : null) }}</td>
                                                    <td>{{ item.isImportForSupplier ? item?.users?.name : (item.created_by ? item?.created_by?.name : null)}}</td>
                                                    <td>{{ item.isImportForSupplier && item.branch ? item?.branch?.name : (item.import_for_supplier && item.import_for_supplier.branch ? item?.import_for_supplier?.branch?.name : null) }}</td>
                                                    <td>{{ n(item.totalPrice) }}</td>
                                                    <td>{{ item.statusName }}</td>
                                                  </template>
                                              </tr>
                                          </template>
                                        </tbody>
                                    </table>
                                    <Pagination :value="paginateDetail" :record="this.numberRecordDetail" v-if="this.paginateDetail.totalRecord >= this.numberRecordDetail"/>
                                </div>
                            </div>
                            <div class="row detail-prd-row flex-end pr-3">
                               <AppPermission :permission="this.$permissions.suppliers.export">
                                  <button type="button" class="btn btn-secondary mx-1" @click.stop="exHistoryIRSupplier(entry.id)">
                                      <i class="fa fa-file-export pr-1"></i>
                                      {{$t('message.common.export')}}
                                  </button>
                                </AppPermission>
                            </div>
                        </td>
                    </tr>
                    </template>
                    </tbody>
                  </table>
                </div>
                <div class="row" v-if="entries.length == 0" id="notFound">
                  <div class="col-sm-12 text-center my-10 text-secondary align-items-center d-flex justify-content-center">
                    <i class="fa fa-hdd-o mr-2 text-secondary"></i>
                    <p v-if="(filter.keyword || this.filterStatus || this.filterSupplierGroups) && this.loading === false">{{$t('message.common.notFound')}}</p>
                    <p v-else-if="this.loading === false">{{$t('message.common.notData')}}</p>
                  </div>
                </div>
                <div class="row" v-if="getDataChecked(this.fields) == 0 && entries.length > 0" id="notFound">
                  <div class="col-sm-12 text-center my-10">
                    <p>{{$t('message.common.notData')}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ModalImportFileExcel ref="ModalImportExcel" @input="load()" />
        <ModalAddInfoSupplierl ref="ModalAddInfoSupplierl" @resetCount="resetCount()" @inputData="load()"/>
        <ModalSuppliersRemove ref="ModalSuppliersRemove" @resetCount="resetCount()" @inputData="load()"/>
        <ModalStopSuppliers ref="ModalStopSuppliers" @resetCount="resetCount()" @inputData="load()"/>
        <ModalAddSuppliersGroup ref="ModalAddSuppliersGroup" @inputData="load()"/>
        <ModalEditSuppliersGroup ref="ModalEditSuppliersGroup" @inputData="load()"/>
        <ModalErrorsMessageImport ref="ModalErrorsMessageImport" />
      </div>
      <DetailStockCard ref="DetailStockCard" />
      <DetailStockCardDetail ref="DetailStockCardDetail" />
      <ModalSelectOptionExport ref="ModalSelectOptionExport" @export="exportFiles"/>
  </AppPermission>
</template>

<script>
    import {$alert, $get, clone, setArray, toastCutomBottom, numberFormat, parseIntEx} from "@/lib/utils";
    import Pagination from "@/components/Pagination";
    import TableSortField from "@/components/TableSortField";
    import ModalSuppliersRemove from "@/components/supplierl/ModalRemoveSuppliers";
    import ModalImportFileExcel from "@/components/supplierl/ModalImportExcel";
    import InputDatePicker from "@/components/forms/InputDatePicker";
    import ModalAddInfoSupplierl from '@/components/supplierl/ModalAddInfoSupplierl';
    import ModalAddSuppliersGroup from '@/components/supplierl/ModalAddSuppliersGroup';
    import ModalEditSuppliersGroup from '@/components/supplierl/ModalEditSuppliersGroup';
    import emitter from "@/lib/emitter";
    import SelectField from "../../components/SelectField";
    import store from "@/store";
    import ModalStopSuppliers from "@/components/supplierl/ModalStopSuppliers";
    import http from '@/services/api';
    import ModalErrorsMessageImport from './ModalErrorsMessageImport.vue';
    import SelectDateTime from "@/components/SelectDateTime.vue";    
    import InputText from "@/components/forms/InputText";
    import AppPermission from '@/components/AppPermission.vue';    
    import DetailStockCard from './tabs/DetailSupplierCard.vue';
    import DetailStockCardDetail from '../products/DetailStockCard';
    import InputNumber from "@/components/forms/InputNumberCustom";
    import exportMixin from "@/exportMixin";
    import ModalSelectOptionExport from '@/components/ModalSelectOptionExport.vue';

    export default {
        name: "SupplierIndex",
        mixins: [exportMixin],
        components: {
            ModalStopSuppliers, InputText, InputNumber, DetailStockCardDetail,
            ModalErrorsMessageImport, TableSortField, Pagination,ModalImportFileExcel, 
            ModalAddInfoSupplierl,SelectField,ModalSuppliersRemove, ModalAddSuppliersGroup, 
            ModalEditSuppliersGroup, InputDatePicker, SelectDateTime,
            AppPermission, DetailStockCard, ModalSelectOptionExport
        },
        mounted() {
          document.title = this.$t('message.supplierIndex.documentTitle');
          const query = this.$route.query;
          this.filter = {...this.filter, ...query};
          const fields = localStorage.getItem('suppliersSelectedFields_' + this.user.id);
          if (fields) {
            this.fields = JSON.parse(fields);
          }
          this.paginate.currentPage = this.$route.query.page || 1;
          if(query['keyword'] != null){
            this.loadDetail();
          }else{
            this.clearFilter();
          }
        },
        data() {
            return {
              filter: {
                  keyword: '',
                  status: '',
                  createdPicker: null,
                  created: null,
              },
              checkedAll: false,
              fields: [],
              entries: [],
              sortData: {
                  field: null,
                  direction: null,
              },
              paginate: {
                  currentPage: 1,
                  lastPage: 1
              },
              numberRecord: 15,
              user: store.state.Auth.user,
              supplierGroups: [],
              filterSupplierGroups: [],
              filterStatus: null,
              filterQuery: {
                from: '',
                to: ''
              },
              otherOption: null,
              excelData: {
                fields: null,
                data: [],
                fileName: ''
              },
              loading: false,
              supplierIdDetail: null,
              headerActive: 'info',
              sortTime: 0,
              sumBuyAll: 0,
              dataDetail: [],
              paginateDetail: {
                  currentPage: 1,
                  lastPage: 1
              },
              numberRecordDetail: 10,
            }
        },
        methods: {
          showDetailStockCard(id, type){                  
              this.$refs.DetailStockCard.show(id, type);
          },
          async resetCount(){
            document.getElementById('chkAll').checked = false;
            this.unChecked('prdChecked');
          },
          showModalSuppliersRemove(id) {
            this.$refs.ModalSuppliersRemove.show(id);
          },
          showModalSuppliersEditById(id){
            this.$refs.ModalAddInfoSupplierl.show(id);
          },
          showModalChangeStatusSupplier(id, status){
            this.$refs.ModalStopSuppliers.show(id, status);
          },
          onMouseLeave(){
            emitter.emit("mouseleave");
          },
          showModalSupplierGroups() {
            this.$refs.ModalAddSuppliersGroup.show();
          },
          showModalEditSupplierGroups(id) {
            this.$refs.ModalEditSuppliersGroup.show(id);
          },
          showModalAddInfo () {
            this.$refs.ModalAddInfoSupplierl.show();
          },
          showModalUploadFileExcel () {
            this.$refs.ModalImportExcel.show();
          },
          //Ẩn hiện dropdown bên trái
          showHide(name,iDown, iUp){
              var dropdownContent = document.getElementById(name);
              dropdownContent.classList.toggle("dp-hide");
              var down = document.getElementById(iDown);
              down.classList.toggle("dp-hide");
              var up = document.getElementById(iUp);
              up.classList.toggle("dp-hide");
          },
          handleCheckSupplierGroups(id = null){
            let arr = this.filterSupplierGroups;
            arr = (arr.includes(id) || !id) ? [] : [id]
            this.filterSupplierGroups = arr
            this.handleFilter('supplierGroups',arr);
          },
          handleFilter(key, value){
              let query = {...this.filterQuery,page:'1'}
              query[key] = JSON.stringify(value)
              this.filterQuery = query
              this.load(query)
          },
          async clearFilter() {
              await this.$router.replace({
                  path: '/suppliers/index',
                  query: {}
              });
              Object.keys(this.filter).forEach(key => {
                  this.filter[key] = '';
              });
              Object.keys(this.sortData).forEach(key => {
                  this.sortData[key] = null;
              });
              await emitter.emit("clearSelectDateTime");
              this.filterSupplierGroups = [];
              this.filterStatus = null;
              this.filterQuery = [];
              this.numberRecord = 20;
              this.supplierIdDetail = null;
              this.load();
          },
          getCheckedIDs() {
              return this.entries.filter(e => e.checked).map(e => e.id);
          },
          onCheckedAll(value) {
              setArray(this.entries, {
                  checked: value
              });
          },
          async doFilter(field, value) {
            if(field == 'status'){
              this.filterStatus = value
            }
            const query = clone(this.$route.query);
            query[field] = value;
            await this.$router.push({query: {...query,page:'1'}});
            const filterQuery = clone(this.filterQuery);
            this.load(filterQuery);
          },
          async sort(field, direction) {
              this.sortData = {
                  field, direction
              };

              const query = clone(this.$route.query);
              query.sort_field = field;
              query.sort_direction = direction;

              await this.$router.push({query: query});
              this.load();


          },
          async pageChange(page) {
            let query = clone(this.$route.query);
            query['page'] = page;
            await this.$router.push({query: query});
            let filterQuery = clone(this.filterQuery);
            filterQuery['page'] = page;
            this.load(filterQuery);
          },
          async onChange(v) {
            this.numberRecord = v.target.value
            let query = clone(this.$route.query);
            query['page']=1;
            query.numberRecord = v.target.value;
            await this.$router.push({query: query});
            this.load();
          },
          detailSupplier(id){
            if (id === this.supplierIdDetail) {
              this.supplierIdDetail = null;
            } else {
              this.supplierIdDetail = id;
              this.headerActive = 'info';
            }
          },
          showContent(name, entry) {
            this.headerActive = name;
            this.dataDetail = [];
            if(this.headerActive === this.$constant.supplier.values.historyOrder){
              if(entry && entry.order_for_supplier){
                  this.dataDetail = this.dataDetail.concat(entry.order_for_supplier);
              }
            }else if(this.headerActive === this.$constant.supplier.values.historyImport){
              if(entry && entry.import_for_suppliers){
                  this.dataDetail = this.dataDetail.concat(entry.import_for_suppliers);
              }
              if(entry && entry.return_import_for_supplier){
                  this.dataDetail = this.dataDetail.concat(entry.return_import_for_supplier);
              }
            }
            if(this.dataDetail){
                this.dataDetail.sort(function(a, b) { return new Date(b.created_at) - new Date(a.created_at) });
            }
            this.setPaginateDataDetail();
          },
          setPaginateDataDetail(){
            this.paginateDetail.currentPage = 1;
            this.paginateDetail.lastPage = Math.ceil(this.dataDetail.length / 10);
            this.paginateDetail.totalRecord = this.dataDetail.length;
          },
          getDataChecked(fields) {
            let count = 0;
            fields.forEach(item => {
              if (item.selected == true) count += 1;
            });
            return count;
          },
          async load(query) {
            
            this.loading = true;
            emitter.emit("onLoading");
            let param =
            {
                ...this.$route.query,
                ...(query ? {...query} : {...this.filterQuery}),
                record:this.numberRecord
            }
            const res = await $get('/v1/suppliers/data', param);           
              if (res.code !== 200) {
                  $alert(res);
                  return;
              }

              setArray(res.data.entries, {
                  checked: false
              });

            if (!this.fields || !this.fields.length) {
              this.fields = res.data.fields;
            }
            this.paginate = res.data.paginate;
            this.entries = res.data.entries;
            this.sumBuyAll = res.data.sumBuyAll;
      
            //Nhóm nhà cung cấp
            const sRes = await $get('/v1/supplier-groups/dataFiller');
            this.supplierGroups = sRes.data.entries;
            emitter.emit("offLoading");
            this.loading = false;
            
          },
          async loadDetail(){
            await emitter.emit("clearSelectDateTime");
            this.filterSupplierGroups = [];
            this.filterStatus = null;
            this.filterQuery = [];
            this.numberRecord = 20;
            await this.load();
            this.entries.forEach((entry) => {
                this.detailSupplier(entry.id);
            });
          },
        selectOptionExport(method = 'exportExcel', totalRecord = 0){
            let params = { method: method };
            totalRecord = totalRecord ? totalRecord : this.paginate.totalRecord;
            this.$refs.ModalSelectOptionExport.show(params, totalRecord, this.fields);
        },
        async exportFiles(params){
            if (params.method) {
                this[params.method](params.firstRecordIndex);
            }
        },
          // Xuất excel
          async exportExcel(firstRecordIndex = 0) {
            emitter.emit("onLoading");
            let param =
            {
                ...this.$route.query,
                ...clone(this.filterQuery),
                firstRecordIndex: firstRecordIndex,
                fields: this.fields,
            }
            const res = await http.download('/v1/suppliers/export', {...param});
            this.returnDataExport(res);
          },
          convertFields(data, name, field){
              if(field == 'debt'){
                  data['Nợ cần trả hiện tại'] = {
                      field: 'debt',
                      callback: (value) => {
                          return this.formatNumber(value);
                      }
                  }
              }else if(field == 'sumBuy'){
                  data['Tổng mua'] = {
                      field: 'sumBuy',
                      callback: (value) => {
                          return this.formatNumber(value);
                      }
                  }
              }else if(field == 'sumBuy-minus-return'){
                  data['Tổng mua trừ trả hàng'] = {
                      field: 'sumBuy-minus-return',
                      callback: (value) => {
                          return this.formatNumber(value);
                      }
                  }
              }else if(field == 'contactNumber'){
                  data['Số điện thoại'] = {
                      field: 'contactNumber',
                      callback: (value) => {
                          return this.formatNumber(value);
                      }
                  }
              }else{
                  data[name] = field
              }
              return data;
          },
          formatNumber(a){
            if (a === null) {
                return null;
            }

            a = parseIntEx(a);
            return numberFormat(a)
          },
          doFilterTime(v){
            const query = clone(this.filterQuery);
            delete query[v.label];
            if(v.value){
                query[v.label] = v.value;
            }
            query['page'] = 1;
            this.filterQuery = {...query};
            this.load();
          },
          showDetail(id, stockCardType){ 
              this.$refs.DetailStockCardDetail.show(id, stockCardType);
          },
          //[NCC] Xuất file lịch sử đặt hàng nhập
          async exHistoryOrderSupplier(id){
            emitter.emit("onLoading");
            const res = await http.download('/v1/suppliers/ex-history-order-supplier', {id: id});          
            this.returnDataExport(res);    
          },
          // [NCC] Xuất file lịch sử nhập/trả hàng
          async exHistoryIRSupplier(id){
            emitter.emit("onLoading");                
            const res = await http.download('/v1/suppliers/ex-history-import-return', {id: id});          
            this.returnDataExport(res);    
          },
        },
        created() {
          emitter.on("changeSupplierGroup", () => {
            this.filterSupplierGroups = [];
            this.handleCheckSupplierGroups();
          });
        },

    }
</script>
<style scoped>
.dropdown:hover .dropdown-menu {
  display: block;
  margin-top: 0;
}

.text-title {
  line-height: 52px;
}

#number_records {
  margin-top: 11px;
}

#typeCard {
  margin-top: -8px;
}

.custom-input {
  border: none;
  border-bottom: 1px solid #f1f1f1;
  outline: none;
}

.dropdown-item>i{
  display: none;
  position: absolute;
  right: 0.5rem;
}
.dropdown-item:hover > i{
  display: inline-block;
}
.dropdown-toggle{
  white-space: normal;
}
.dropdown-toggle::after{
  position: absolute;
  right: 0.85rem;
  top: 0.75rem;
}
.dropdown-item{
  white-space: normal;
  overflow-wrap: break-word;
}
.note-supplier{
  width: 88%;
  height: 100%; 
  background: transparent;
}
input[type="date"]:not(.has-value):before{
  content: attr(placeholder);
}
.icon-calendar {
    position: absolute;
    right: 25px;
    top: 11px;
    font: normal normal normal 14px/1;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.no-boder {
    border-top: none !important;
    padding-bottom: 0 !important;
}

/* .backgroup__detail {
    background: #B1DDF0 !important;
} */

.head-detail {
    min-width: 100px;
    display: block;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.backgroup__detail-active {
    background: #fff !important;
}

.border-left {
    border-left: 1px solid #D9D9D9;
}
.note-detail {
    margin-left: 20px;
    border: none;
    overflow: auto;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    resize:none;
    width: 400px;
}

.label__note {
  color: #C7C5C5;
  padding: 10px;
}
.container input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
</style>