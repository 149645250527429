<template>
 <div ref="modal" class="modal mx-0 fade" tabindex="-1" role="dialog" id="modalInformationCustomer" data-backdrop="static">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header py-1 mx-2 border-none">
                <h5 class="modal-title py-2">
                    {{$t('message.customerIndex.tabs.address')}}
                </h5>
                <button type="button" class="close" @click="closed()">
                    <span>&times;</span>
                </button>
            </div>
            <div class="modal-body py-0 px-3">
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active">
                        <div class="mt-2 mx-4 pb-2 mb-4">
                            <div class="row detail-prd-row mt-3 m-0 w-100">
                                <div class="col-12 p-0">
                                    <table class="w-100">
                                        <thead>
                                            <tr class="backgroup__detail table-header border-1">
                                                <th scope="col" class="checkbox-table pl-4 cell-code-coupon p-2"></th>
                                                <th scope="col" class="pl-4 cell-code-coupon p-2">
                                                    {{$t('message.customerIndex.addressName')}}
                                                </th>
                                                <th scope="col" class="pl-4 cell-auto p-2">
                                                    {{$t('message.customerIndex.receiverName')}}
                                                </th>
                                                <th scope="col" class="pl-4 cell-auto p-2">
                                                    {{$t('message.customerIndex.phone')}}
                                                </th>
                                                <th scope="col" class="pl-4 cell-release-coupon p-2">
                                                    {{$t('message.customerIndex.address')}}
                                                </th>
                                                <th scope="col" class="checkbox-table pl-4 cell-code-coupon p-2"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-if="this.entry && this.entry.length">
                                                <template v-for="(el, index) in this.entry" :key="index">
                                                    <tr class="border">
                                                        <template v-if="index >= (this.paginateDetail.currentPage - 1)*10 && index < this.paginateDetail.currentPage*10">
                                                            <td scope="col" class="checkbox-table pl-4 cell-code-coupon p-2">
                                                                <i class="far fa-trash-alt" @click="showModalDeletePickup(el.id)"></i>
                                                            </td>
                                                            <td scope="col" class="pl-4 cell-code-coupon p-2" v-text="el.nameAddress"></td>
                                                            <td scope="col" class="pl-4 cell-auto p-2" v-text="el.name"></td>
                                                            <td scope="col" class="pl-4 cell-auto p-2" v-text="el.phone"></td>
                                                            <td scope="col" class="pl-4 cell-release-coupon p-2" 
                                                                v-text="el.addressValue + (el.wards ? ',' + el.wards.name : null) + (el.districts ? ',' + el.districts.name : null) + (el.provinces ? ',' + el.provinces.name : null)">
                                                            </td>
                                                            <td scope="col" class="checkbox-table pl-4 cell-code-coupon p-2">
                                                                <button class="rounded px-2 py-1 button-choose" @click="chooseAddress(el)">
                                                                    {{ $t('message.button.choose') }}
                                                                </button>
                                                            </td>
                                                        </template>
                                                    </tr>
                                                </template>
                                            </template>
                                            <template v-else>
                                                <tr>
                                                    <td colspan="6" class="text-center text-muted">{{ $t('message.common.notData') }}</td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                    <Pagination :value="paginateDetail" :record="10" v-if="this.paginateDetail.totalRecord >= 10"/>
                                </div>
                            </div>
                        </div>
                        <div class="row mx-4 my-0 pb-4 mt-0">
                            <div class="col-6">
                            </div>
                            <div class="col-6">
                                <div class="row flex-end mt-4" >
                                    <button type="button" class="btn btn-save mx-1" @click="openModal()">
                                        {{$t('message.common.newAddress')}}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ModalCreateUpdateDelivery ref="ModalCreateUpdateDelivery" @updated="updateValue"></ModalCreateUpdateDelivery>
<ModalConfirm ref="ModalConfirm" @updated="loadDataDeliveries"/>
</template>
<script>
import Pagination from "@/components/Pagination";
import { $post } from '@/lib/utils';
import toastr from 'toastr';
import ModalCreateUpdateDelivery from '@/views/customers/ModalCreateUpdateDelivery';
import ModalConfirm from "@/views/customers/tabs/ModalConfirmDelete.vue";

export default {
    name: "ModalInformationCustomer",
    emits: ['changeData'],
    data(){
        return {
            entry: [],
            paginateDetail: {
                currentPage: 1,
                lastPage: 1,
            },
            customer_id: null,
            customer_code_address: null
        }
    },
    props: [],
    components: {Pagination, ModalCreateUpdateDelivery, ModalConfirm},
    methods: {
        show(entry = null, id = null, code = null) {
            if(entry){
                this.entry = entry ? entry : [];
                this.customer_id = id ?? null;
                this.customer_code_address = code;
                this.loadDataDeliveries();
                window.jQuery(this.$refs.modal).modal('show');
            }
        },
        closed() {
            window.jQuery(this.$refs.modal).modal('hide');
        },
        chooseAddress(value){
            this.$emit('changeData', value);
            this.closed();
        },
        openModal(){
            this.$refs.ModalCreateUpdateDelivery.show(this.customer_id, this.customer_code_address);
        },
        updateValue() {
            this.$emit('reloadData');
            this.loadDataDeliveries();
        },
        showModalDeletePickup (id) {
            this.$refs.ModalConfirm.show(id);
        },
        async loadDataDeliveries ()
        {
            const result = await $post('/v1/customers/get-deliveries', {id: this.customer_id});
            this.entry = result;
            this.setPagination();
        },
        setPagination ()
        {
            this.paginateDetail.currentPage = 1;
            this.paginateDetail.lastPage = Math.ceil(this.entry.length / 10);
            this.paginateDetail.totalRecord = this.entry.length;
        }
    },
    beforeUnmount() {
        this.closed();
    },
    unmounted() {
        this.closed();
    }, 
}
</script>
<style scoped lang="scss">
.button-choose{
    background: white;
    border: 1px solid gray;
    outline: none;
}
</style>

