<template>
    <div ref="modalConfirm" class="modal mx-0 fade" tabindex="-1" role="dialog" id="modalConfirm">
        <div class="modal-dialog modal-lg" role="document" style="width: 800px">
            <div class="modal-content px-4">
                <div class="modal-header py-1">
                    <h6 class="modal-title py-2">{{!isImportSupplier ? $t('message.modalPaymentSupplier.title_order_for_supplier', {code: this.code}) :  $t('message.modalPaymentSupplier.title_import_for_supplier', {code: this.code})}}</h6>
                    <button type="button" class="close" data-dismiss="modalConfirm" aria-label="Close" @click="hide()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body my-4 py-0 mx-3 px-0" >
                    <div class="row detail-prd-row mt-3 m-0 w-100">
                        <div class="col-12 p-0">
                            <table class="w-100">
                                <thead>
                                    <tr class="backgroup__detail table-header border-1">
                                        <th scope="col" class="pl-4 cell-auto p-2">
                                            {{ $t('message.modalPaymentSupplier.code') }}
                                        </th>
                                        <th scope="col" class="pl-4 cell-release-coupon p-2">
                                            {{ $t('message.modalPaymentSupplier.created_at') }}
                                        </th>
                                        <th scope="col" class="pl-4 cell-auto p-2">
                                            {{ $t('message.modalPaymentSupplier.amount') }}
                                        </th>
                                        <th scope="col" class="pl-4 cell-status-coupon p-2">
                                            {{ $t('message.modalPaymentSupplier.method') }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-if="this.defaultValues.filter?.length > 0">
                                        <template v-for="(item, index) in this.defaultValues.filter" :key="item.id">
                                            <tr class="table-header border"  :class="index % 2 ? 'bg-white' : 'bg-light'">
                                                <template v-if="index >= (this.paginateDetail.currentPage - 1)*10 && index < this.paginateDetail.currentPage*10">
                                                    <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal" v-text="item.code"></th>
                                                    <th scope="col" class="pl-4 cell-release-coupon p-2 font-weight-normal" v-text="dt(item.created_at)"></th>
                                                    <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal" v-text="n(item.amount)"></th>
                                                    <th scope="col" class="pl-4 cell-status-coupon p-2 font-weight-normal" v-text="item.method"></th>
                                                </template>
                                            </tr>
                                        </template>
                                    </template>
                                </tbody>
                            </table>
                            <Pagination :value="paginateDetail" :record="10" v-if="this.paginateDetail.totalRecord >= 10"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
</style>
<script>
import Pagination from "@/components/Pagination";
export default {
    name: "ModalPaymentSupplier",
    components: {Pagination},
    data(){

        return {
            entry:null,
            code:null,
            paginateDetail: {
                currentPage: 1,
                lastPage: 1,
            },
            defaultValues:{
                filter: null,
                all: null,
            },
            isImportSupplier: false,
        }
    },
    methods: {
        show(entry, code, isImportSupplier = false) {
            if(entry){
                this.entry = entry;
                this.code = code;
                this.isImportSupplier = isImportSupplier;
                if(entry.paymentsInfo){
                    this.defaultValues.filter = entry.paymentsInfo;
                    this.setPaginate(entry.paymentsInfo);
                }

                window.jQuery(this.$refs.modalConfirm).modal('show');
            }
        },
        setPaginate(items){
            let arr = [];
            if(items){
                arr = items;
            }
            this.paginateDetail.currentPage = 1;
            this.paginateDetail.lastPage = Math.ceil(arr.length / 10);
            this.paginateDetail.totalRecord = arr.length;
        },
        hide(){
            this.entry = null;
            this.code = null;
            this.paginateDetail = {
                currentPage: 1,
                lastPage: 1,
            };
            this.defaultValues = {
                filter: null,
                all: null,
            };
            window.jQuery(this.$refs.modalConfirm).modal('hide');
        },
    },
    beforeUnmount() {
        this.hide();
    },
}
</script>

