<template>
    <div ref="modal" class="modal mx-0 fade" tabindex="-1" role="dialog" data-backdrop="static">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header py-1 border-bottom-0">
                    <h6 class="modal-title py-2 px-4" v-if="this.ticketType == this.$constant.TYPE_SRC_ORDER_SUPPLIER">
                        {{ $t('message.OrderSupplierIndex.title') }}
                    </h6>
                    <h6 class="modal-title py-2 px-4" v-if="this.ticketType == this.$constant.TYPE_SRC_RETURN_IMPORT_SUPPLIER">
                        {{ $t('message.headers.return_purchase_orders') }}
                    </h6>
                    <h6 class="modal-title py-2 px-4" v-if="this.ticketType == this.$constant.TYPE_SRC_IMPORT_SUPPLIER">
                        {{$t('message.PurchaseOrderIndex.title')}}
                    </h6>
                    <h6 class="modal-title py-2 px-4" v-if="this.ticketType == this.$constant.TYPE_SRC_SUPPLIER">
                        {{$t('message.headers.suppliers')}}
                    </h6>
                    <button type="button" class="close" aria-label="Close" @click="hide()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
    
                <div class="modal-body py-0 px-3">
                    <div class="tab-content" id="myTabContent" v-if="this.entries?.length > 0">
                        <div class="tab-pane fade show active" v-for="entry in this.entries" :key="entry.id">
                            <div class="row mx-4 my-0 pb-0 mt-1">
                                <template v-if="this.ticketType != this.$constant.TYPE_SRC_SUPPLIER">
                                    <div class="col-4 pl-0">
                                        <div class="row border-bottom-1 m-0">
                                            <div class="col-4 d-flex align-items-center pl-0" v-if="this.ticketType == this.$constant.TYPE_SRC_IMPORT_SUPPLIER">{{$t('message.InputQuickSearch.title.purchase_order')}}:</div>
                                            <div class="col-4 d-flex align-items-center pl-0" v-if="this.ticketType == this.$constant.TYPE_SRC_RETURN_IMPORT_SUPPLIER">{{$t('message.PurchaseOrderIndex.tabs.table.history_return.code')}}:</div>
                                            <div class="col-4 d-flex align-items-center pl-0" v-if="this.ticketType == this.$constant.TYPE_SRC_ORDER_SUPPLIER">{{$t('message.InputQuickSearch.title.order_supplier')}}:</div>
                                            <div class="col-8 pl-0">
                                                <div class="cate_selected d-flex align-items-center px-2 my-1 text-bold" v-text="entry.code"></div>
                                            </div>
                                        </div>
                                        <div class="row border-bottom-1 m-0">
                                            <div class="col-4 d-flex align-items-center pl-0">{{$t('message.common.time')}}:</div>
                                            <div class="col-8 pl-0">
                                                <div class="cate_selected d-flex align-items-center px-2 my-1" v-text="dt(entry.importDate)" v-if="this.ticketType == this.$constant.TYPE_SRC_IMPORT_SUPPLIER"></div>
                                                <div class="cate_selected d-flex align-items-center px-2 my-1" v-text="dt(entry.returnDate)" v-else-if="this.ticketType == this.$constant.TYPE_SRC_RETURN_IMPORT_SUPPLIER"></div>
                                                <div class="cate_selected d-flex align-items-center px-2 my-1" v-text="dt(entry.orderDate)" v-else></div>
                                            </div>
                                        </div>
                                        <div class="row border-bottom-1 m-0">
                                            <div class="col-4 d-flex align-items-center pl-0">{{$t('message.headers.suppliers')}}:</div>
                                            <div class="col-8 pl-0">
                                                <div class="cate_selected d-flex align-items-center px-2 my-1 text-primary">
                                                    <a :href="'/suppliers/index?keyword=' + entry?.supplier?.code" target="_blank" class="text-decoration-none text-primary" v-if="this.ticketType == this.$constant.TYPE_SRC_IMPORT_SUPPLIER || this.ticketType == this.$constant.TYPE_SRC_RETURN_IMPORT_SUPPLIER">{{ entry?.supplier?.name }}</a>
                                                    <a :href="'/suppliers/index?keyword=' + entry.supplierCode" target="_blank" class="text-decoration-none text-primary" v-else>{{ entry.supplierName }}</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row border-bottom-1 m-0" v-if="(this.ticketType == this.$constant.TYPE_SRC_IMPORT_SUPPLIER && entry.createBy)">
                                            <div class="col-4 d-flex align-items-center pl-0">{{$t('message.common.created_by')}}:</div>
                                            <div class="col-8 pl-0">
                                                <div class="cate_selected d-flex align-items-center px-2 my-1" v-text="entry.createBy ? entry.createBy : null"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-4 pl-0">
                                        <div class="row border-bottom-1 m-0" v-if="this.ticketType == this.$constant.TYPE_SRC_IMPORT_SUPPLIER">
                                            <div class="col-4 d-flex align-items-center pl-0">{{$t('message.InputQuickSearch.title.order_supplier')}}:</div>
                                            <div class="col-8 pl-0">
                                                <div class="cate_selected d-flex align-items-center px-2 my-1 text-primary">
                                                    <a :href="'/order-suppliers/index?order_supplier=' + entry.orderForSupplierId" target="_blank" class="text-decoration-none text-primary">{{ entry.orderForSupplierId }}</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row border-bottom-1 m-0">
                                            <div class="col-4 d-flex align-items-center pl-0">{{$t('message.common.status')}}:</div>
                                            <div class="col-8 pl-0">
                                                <div class="cate_selected d-flex align-items-center px-2 my-1" v-text="entry.statusText" v-if="this.ticketType == this.$constant.TYPE_SRC_RETURN_IMPORT_SUPPLIER"></div>
                                                <div class="cate_selected d-flex align-items-center px-2 my-1" v-text="entry.statusName ? entry.statusName : entry.status" v-else></div>
                                            </div>
                                        </div>
                                        <div class="row border-bottom-1 m-0" v-if="this.ticketType == this.$constant.TYPE_SRC_IMPORT_SUPPLIER">
                                            <div class="col-4 d-flex align-items-center pl-0">{{$t('message.common.branch')}}:</div>
                                            <div class="col-8 pl-0">
                                                <div class="cate_selected d-flex align-items-center px-2 my-1" v-text="entry.branchId" v-if="this.ticketType == this.$constant.TYPE_SRC_IMPORT_SUPPLIER || this.ticketType == this.$constant.TYPE_SRC_RETURN_IMPORT_SUPPLIER"></div>
                                                <div class="cate_selected d-flex align-items-center px-2 my-1" v-text="entry.branchName" v-else></div>
                                            </div>
                                        </div>
                                        <div class="row border-bottom-1 m-0" v-if="this.ticketType == this.$constant.TYPE_SRC_RETURN_IMPORT_SUPPLIER">
                                            <div class="col-4 d-flex align-items-center pl-0">{{$t('message.common.created_by')}}:</div>
                                            <div class="col-8 pl-0">
                                                <div class="cate_selected d-flex align-items-center px-2 my-1" v-text="entry.createdBy"></div>
                                            </div>
                                        </div>
                                        <div class="row border-bottom-1 m-0" v-if="this.ticketType == this.$constant.TYPE_SRC_IMPORT_SUPPLIER || this.ticketType == this.$constant.TYPE_SRC_RETURN_IMPORT_SUPPLIER">
                                            <div class="col-4 d-flex align-items-center pl-0" v-text="this.ticketType == this.$constant.TYPE_SRC_IMPORT_SUPPLIER ? $t('message.PurchaseOrderIndex.filter.import_by') + ':' : $t('message.PurchaseOrderIndex.tabs.table.history_return.receive_by') + ':'"></div>
                                            <div class="col-8 pl-0">
                                                <div class="cate_selected d-flex align-items-center px-2 my-1" v-text="entry.userName"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-4" id="noteRO">  
                                        <div class="row p-0 font-italic">
                                            <div class="col-12 text-gray">
                                                <span class="noteStyle text-gray font-italic">
                                                    {{ entry.description ? entry.description : (entry.note ? entry.note : $t('message.InvoiceIndex.note'))}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="col-6 pl-0 mb-4">
                                        <div class="row border-bottom-1 m-0">
                                            <div class="col-4 d-flex align-items-center pl-0">{{$t('message.supplierIndex.code')}}:</div>
                                            <div class="col-8 pl-0">
                                                <div class="cate_selected d-flex align-items-center px-2 my-1 text-primary">
                                                    <a :href="'/suppliers/index?keyword=' + entry.code" target="_blank" class="text-decoration-none text-primary">{{ entry.code }}</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row border-bottom-1 m-0">
                                            <div class="col-4 d-flex align-items-center pl-0">{{$t('message.supplierIndex.address')}}:</div>
                                            <div class="col-8 pl-0">
                                                <div class="cate_selected d-flex align-items-center px-2 my-1" v-text="entry.address"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 mb-4">
                                        <div class="row border-bottom-1 m-0">
                                            <div class="col-4 d-flex align-items-center pl-0">{{$t('message.supplierIndex.name')}}:</div>
                                            <div class="col-8 pl-0">
                                                <div class="cate_selected d-flex align-items-center px-2 my-1" v-text="entry.name"></div>
                                            </div>
                                        </div>
                                        <div class="row border-bottom-1 m-0">
                                            <div class="col-4 d-flex align-items-center pl-0">{{$t('message.customerIndex.phone')}}:</div>
                                            <div class="col-8 pl-0">
                                                <div class="cate_selected d-flex align-items-center px-2 my-1" v-text="entry.contactNumber"></div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                            <template v-if="this.ticketType != this.$constant.TYPE_SRC_SUPPLIER">
                                <div class="mt-2 mx-4 pb-2 mb-4">
                                    <div class="row detail-prd-row mt-3 m-0 w-100">
                                        <div class="col-12 p-0">
                                            <table class="w-100">
                                                <thead>
                                                    <tr class="backgroup__detail table-header border-1" v-if="this.ticketType == this.$constant.TYPE_SRC_IMPORT_SUPPLIER">
                                                        <th scope="col" class="pl-4 cell-code-coupon p-2">{{$t('message.DetailOrdersCard.prodCode')}}</th>
                                                        <th scope="col" class="pl-4 cell-auto p-2">{{$t('message.DetailOrdersCard.prodName')}}</th>
                                                        <th scope="col" class="pl-4 cell-release-coupon p-2">{{$t('message.DetailOrdersCard.prodQuantity')}}</th>
                                                        <th scope="col" class="pl-4 cell-release-coupon p-2">{{$t('message.DetailOrdersCard.prodPrice')}}</th>
                                                        <th scope="col" class="pl-4 cell-release-coupon p-2">{{$t('message.DetailOrdersCard.prodDiscount')}}</th>
                                                        <th scope="col" class="pl-4 cell-release-coupon p-2">{{$t('message.DetailOrdersCard.prodImportPrice')}}</th>
                                                        <th scope="col" class="pl-4 cell-release-coupon p-2">{{$t('message.DetailOrdersCard.totalPrice')}}</th>
                                                    </tr>
                                                    <tr class="backgroup__detail table-header border-1" v-if="this.ticketType == this.$constant.TYPE_SRC_RETURN_IMPORT_SUPPLIER">
                                                        <th scope="col" class="pl-4 cell-code-coupon p-2">{{$t('message.DetailOrdersCard.prodCode')}}</th>
                                                        <th scope="col" class="pl-4 cell-auto p-2">{{$t('message.DetailOrdersCard.prodName')}}</th>
                                                        <th scope="col" class="pl-4 cell-auto p-2">{{$t('message.DetailOrdersCard.prodUnit')}}</th>
                                                        <th scope="col" class="pl-4 cell-release-coupon p-2">{{$t('message.DetailOrdersCard.prodQuantity')}}</th>
                                                        <th scope="col" class="pl-4 cell-release-coupon p-2">{{$t('message.DetailOrdersCard.prodImportPrice')}}</th>
                                                        <th scope="col" class="pl-4 cell-release-coupon p-2">{{$t('message.ReturnPurchaseOrder.returnPrice')}}</th>
                                                        <th scope="col" class="pl-4 cell-release-coupon p-2">{{$t('message.ReturnPurchaseOrder.returnDiscount')}}</th>
                                                        <th scope="col" class="pl-4 cell-release-coupon p-2">{{$t('message.DetailOrdersCard.totalPrice')}}</th>
                                                    </tr>
                                                    <tr class="backgroup__detail table-header border-1" v-if="this.ticketType == this.$constant.TYPE_SRC_ORDER_SUPPLIER">
                                                        <th scope="col" class="pl-4 cell-code-coupon p-2">{{$t('message.DetailOrdersCard.prodCode')}}</th>
                                                        <th scope="col" class="pl-4 cell-auto p-2">{{$t('message.DetailOrdersCard.prodName')}}</th>
                                                        <th scope="col" class="pl-4 cell-auto p-2">{{$t('message.DetailOrdersCard.prodUnit')}}</th>
                                                        <th scope="col" class="pl-4 cell-release-coupon p-2">{{$t('message.DetailOrdersCard.prodQuantity')}}</th>
                                                        <th scope="col" class="pl-4 cell-release-coupon p-2">{{$t('message.DetailOrdersCard.prodPrice')}}</th>
                                                        <th scope="col" class="pl-4 cell-release-coupon p-2">{{$t('message.DetailOrdersCard.prodDiscount')}}</th>
                                                        <th scope="col" class="pl-4 cell-release-coupon p-2">{{$t('message.DetailOrdersCard.prodImportPrice')}}</th>
                                                        <th scope="col" class="pl-4 cell-release-coupon p-2">{{$t('message.DetailOrdersCard.totalPrice')}}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr class="table-header border">
                                                        <th scope="col" class="pl-4 py-2"> 
                                                            <input type="text" v-model="this.defaultValues.searchCode" @input="onInputFilterDetailOrderSupplier('searchCode')" :placeholder="$t('message.DetailOrdersCard.searchProductCode')" class="border-none bg-transparent">
                                                        </th>
                                                        <th scope="col" class="pl-4 py-2"> 
                                                            <input type="text" v-model="this.defaultValues.searchName" @input="onInputFilterDetailOrderSupplier('searchName')" :placeholder="$t('message.DetailOrdersCard.searchProductName')" class="border-none bg-transparent">
                                                        </th>
                                                        <th colspan="6"></th>
                                                    </tr>
                                                    <template v-if="this.defaultValues.filter?.length > 0 && this.ticketType == this.$constant.TYPE_SRC_IMPORT_SUPPLIER">
                                                        <template v-for="(item, index) in this.defaultValues.filter" :key="item.id">
                                                            <tr class="table-header border"  :class="index % 2 ? 'bg-white' : 'bg-light'">
                                                                <template v-if="index >= (this.paginateDetail.currentPage - 1)*10 && index < this.paginateDetail.currentPage*10">
                                                                    <th scope="col" class="pl-4 cell-code-coupon p-2">
                                                                        <a :href="'/products/index?code=' + item.product?.code" target="_blank" class="text-decoration-none text-primary">{{item.product?.code}}</a>
                                                                    </th>
                                                                    <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal">
                                                                        <span v-text="item.product?.name"></span>
                                                                        <span class="description font-italic text-gray" v-text="item.note" :title="item.note"></span>
                                                                    </th>
                                                                    <th scope="col" class="pl-4 cell-release-coupon p-2 font-weight-normal" v-text="n(item.quantity)"></th>
                                                                    <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal" v-text="n(item.price)"></th>
                                                                    <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal" v-text="n(item.discount / item.quantity)"></th>
                                                                    <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal" v-text="n(item.price - (item.discount / item.quantity))"></th>
                                                                    <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal" v-text="n(item.subTotal)"></th>
                                                                </template>
                                                            </tr>
                                                            <tr class="table-header border bg-white" v-if="item.consignments && item.consignments.length">
                                                                <th></th>
                                                                <th :colspan="7">
                                                                    <template v-for="(value, index) in item.consignments" :key="index" >
                                                                        <div class="mr-1 mt-1 mb-2">
                                                                            <span class="consignment_name mr-2 text-white">{{ value.name + ' - ' + d(value.expried) + ' - ' + $t('message.common.shortQuantity') + ':' + value.quantity}}</span>
                                                                        </div>
                                                                    </template>
                                                                </th>
                                                            </tr>
                                                        </template>
                                                    </template>
                                                    <template v-if="this.defaultValues.filter?.length > 0 && this.ticketType == this.$constant.TYPE_SRC_RETURN_IMPORT_SUPPLIER">
                                                        <template v-for="(item, index) in this.defaultValues.filter" :key="item.id">
                                                            <tr class="table-header border"  :class="index % 2 ? 'bg-white' : 'bg-light'">
                                                                <template v-if="index >= (this.paginateDetail.currentPage - 1)*10 && index < this.paginateDetail.currentPage*10">
                                                                    <th scope="col" class="pl-4 cell-code-coupon p-2">
                                                                        <a :href="'/products/index?code=' + item.product?.code" target="_blank" class="text-decoration-none text-primary">{{item.product?.code}}</a>
                                                                    </th>
                                                                    <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal">
                                                                        <span v-text="item.product?.name"></span>
                                                                        <span class="description font-italic text-gray" v-text="item.note" :title="item.note"></span>
                                                                    </th>
                                                                    <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal" v-text="item.unit?.unitName"></th>
                                                                    <th scope="col" class="pl-4 cell-release-coupon p-2 font-weight-normal" v-text="n(item.quantity)"></th>
                                                                    <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal" v-text="n(item.importPrice)"></th>
                                                                    <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal" v-text="n(item.price)"></th>
                                                                    <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal" v-text="n(item.discount / item.quantity)"></th>
                                                                    <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal" v-text="n(item.subTotal)"></th>
                                                                </template>
                                                            </tr>
                                                            <tr class="table-header border bg-white" v-if="item.consignments && item.consignments?.length">
                                                                <th></th>
                                                                <th :colspan="7">
                                                                    <template v-for="(value, index) in item.consignments" :key="index" >
                                                                        <div class="mr-1 mt-1 mb-2">
                                                                            <span class="consignment_name mr-2 text-white">{{ value.name + ' - ' + d(value.expried) + ' - ' + $t('message.common.shortQuantity') + ':' + value.quantity}}</span>
                                                                        </div>
                                                                    </template>
                                                                </th>
                                                            </tr>
                                                        </template>
                                                    </template>
                                                    <template v-if="this.defaultValues.filter?.length > 0 && this.ticketType == this.$constant.TYPE_SRC_ORDER_SUPPLIER">
                                                        <template v-for="(item, index) in this.defaultValues.filter" :key="item.id">
                                                            <tr class="table-header border"  :class="index % 2 ? 'bg-white' : 'bg-light'">
                                                                <template v-if="index >= (this.paginateDetail.currentPage - 1)*10 && index < this.paginateDetail.currentPage*10">
                                                                    <th scope="col" class="pl-4 cell-code-coupon p-2">
                                                                        <a :href="'/products/index?code=' + item.product?.code" target="_blank" class="text-decoration-none text-primary">{{item.product?.code}}</a>
                                                                    </th>
                                                                    <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal">
                                                                        <span v-text="item.product?.name"></span>
                                                                        <span class="description font-italic text-gray" v-text="item.note" :title="item.note"></span>
                                                                    </th>
                                                                    <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal" v-text="item.unit?.unitName"></th>
                                                                    <th scope="col" class="pl-4 cell-release-coupon p-2 font-weight-normal" v-text="n(item.quantity)"></th>
                                                                    <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal" v-text="n(item.price)"></th>
                                                                    <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal" v-text="n(item.discount)"></th>
                                                                    <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal" v-text="n(item.price - (item.discount / item.quantity))"></th>
                                                                    <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal" v-text="n(item.subTotal)"></th>
                                                                </template>
                                                            </tr>
                                                            <tr class="table-header border bg-white" v-if="item.consignments && item.consignments?.length">
                                                                <th></th>
                                                                <th :colspan="7">
                                                                    <template v-for="(value, index) in item.consignments" :key="index" >
                                                                        <div class="mr-1 mt-1 mb-2">
                                                                            <span class="consignment_name mr-2 text-white">{{ value.name + ' - ' + d(value.expried) + ' - ' + $t('message.common.shortQuantity') + ':' + value.quantity}}</span>
                                                                        </div>
                                                                    </template>
                                                                </th>
                                                            </tr>
                                                        </template>
                                                    </template>
                                                </tbody>
                                            </table>
                                            <Pagination :value="paginateDetail" :record="10" v-if="this.paginateDetail.totalRecord >= 10"/>
                                            <div class="no-data border border-top-0" v-if="entry.invoice_items?.length == 0 || this.defaultValues.filter?.length == 0">
                                                <span>{{$t('message.common.notFound')}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mx-4 my-0 pb-4 mt-0">
                                    <div class="col-6">
                                        <div class="row mb-2" v-if="entry.coupon_detail">
                                            <div class="col-12 p-3 border-dotted-2 rounded">
                                                <p class="text-bold m-0">
                                                    <i class="fa-solid fa-badge-percent"></i>{{$t('message.DetailOrdersCard.codeDiscount')}}
                                                </p>
                                                <span v-if="entry.messageCoupon" v-text="entry.messageCoupon"></span>
                                            </div>
                                        </div>
                                        <div class="row" v-if="entry.messagePromotion">
                                            <div class="col-12 p-3 border-dotted-2 rounded">
                                                <p class="text-bold m-0">
                                                    <span class="btn-icon-gift mr-2 text-danger"><i class="fa fa-gift"></i></span>
                                                    {{$t('message.DetailOrdersCard.promotion')}}
                                                </p>
                                                <span v-text="entry.messagePromotion"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="row m-2 mr-0">
                                            <div class="col-6 flex-end">{{$t('message.DetailOrdersCard.totalQuantity')}}:</div>
                                            <div class="col-6 p-0 flex-end" v-text="n(entry.totalQuantity)" v-if="this.ticketType == this.$constant.TYPE_SRC_IMPORT_SUPPLIER || this.ticketType == this.$constant.TYPE_SRC_RETURN_IMPORT_SUPPLIER"></div>
                                            <div class="col-6 p-0 flex-end" v-text="n(entry.totalProduct)" v-else></div>
                                        </div>
                                        <div class="row m-2 mr-0" v-if="this.ticketType == this.$constant.TYPE_SRC_IMPORT_SUPPLIER || this.ticketType == this.$constant.TYPE_SRC_RETURN_IMPORT_SUPPLIER">
                                            <div class="col-6 flex-end">{{$t('message.TransferIndex.total_products')}}:</div>
                                            <div class="col-6 p-0 flex-end" v-text="n(entry.totalProduct)" ></div>
                                        </div>
                                        <div class="row m-2 mr-0">
                                            <div class="col-6 flex-end">{{$t('message.DetailOrdersCard.total')}}:</div>
                                            <div class="col-6 p-0 flex-end" v-text="n(entry.totalPrice)" v-if="this.ticketType == this.$constant.TYPE_SRC_IMPORT_SUPPLIER || this.ticketType == this.$constant.TYPE_SRC_RETURN_IMPORT_SUPPLIER"></div>
                                            <div class="col-6 p-0 flex-end" v-text="n(entry.totalOrigin)" v-else></div>
                                        </div>
                                        <div class="row m-2 mr-0">
                                            <div class="col-6 flex-end" v-text="(this.ticketType == this.$constant.TYPE_SRC_IMPORT_SUPPLIER || this.ticketType == this.$constant.TYPE_SRC_RETURN_IMPORT_SUPPLIER) ? $t('message.DetailOrdersCard.prodDiscount') + ':' : $t('message.InvoiceIndex.discount') + ':'"></div>
                                            <div class="col-6 p-0 flex-end" v-text="(this.ticketType == this.$constant.TYPE_SRC_IMPORT_SUPPLIER || this.ticketType == this.$constant.TYPE_SRC_RETURN_IMPORT_SUPPLIER) ? n(entry.priceDiscount) : n(entry.discountPrice)"></div>
                                        </div>
                                        <div class="row m-2 mr-0" v-if="this.ticketType == this.$constant.TYPE_SRC_IMPORT_SUPPLIER || this.ticketType == this.$constant.TYPE_SRC_RETURN_IMPORT_SUPPLIER">
                                            <div class="col-6 flex-end" v-text="this.ticketType == this.$constant.TYPE_SRC_RETURN_IMPORT_SUPPLIER ? $t('message.ReturnPurchaseOrder.supplierNeedToPay') + ':' :  $t('message.OrderIndex.total') + ':'"></div>
                                            <div class="col-6 p-0 flex-end" v-text="n(entry.returnSupplier)"></div>
                                        </div>
                                        <div class="row m-2 mr-0" v-if="this.ticketType == this.$constant.TYPE_SRC_RETURN_IMPORT_SUPPLIER">
                                            <div class="col-6 flex-end">{{$t('message.ReturnPurchaseOrder.supplierPaid')}}:</div>
                                            <div class="col-6 p-0 flex-end" v-text="n(entry.supplierPayment)"></div>
                                        </div>
                                        <div class="row m-2 mr-0" v-if="this.ticketType == this.$constant.TYPE_SRC_IMPORT_SUPPLIER">
                                            <div class="col-6 flex-end">{{$t('message.OrderSupplierIndex.total_payment')}}:</div>
                                            <div class="col-6 p-0 flex-end" v-text="n(entry.paymentForSupplier)"></div>
                                        </div>
                                        <div class="row m-2 mr-0" v-if="this.ticketType != this.$constant.TYPE_SRC_IMPORT_SUPPLIER && this.ticketType != this.$constant.TYPE_SRC_RETURN_IMPORT_SUPPLIER">
                                            <div class="col-6 flex-end">{{$t('message.InvoiceIndex.total')}}:</div>
                                            <div class="col-6 p-0 flex-end" v-text="n(entry.total)"></div>
                                        </div>
                                        <div class="row m-2 mr-0" v-if="this.ticketType != this.$constant.TYPE_SRC_IMPORT_SUPPLIER && this.ticketType != this.$constant.TYPE_SRC_RETURN_IMPORT_SUPPLIER">
                                            <div class="col-6 flex-end">{{$t('message.InvoiceIndex.total_payment')}}:</div>
                                            <div class="col-6 p-0 flex-end" v-text="n(entry.totalPayment)"></div>
                                        </div>
                                        <div class="row flex-end mt-4" >
                                            <button type="button" class="btn btn-save mx-1" @click="open(entry.code)">
                                                <i class="fa fa-share mr-2"></i>{{$t('message.DetailOrdersCard.btnOpen')}}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import {$post, removeVietnameseTones} from "@/lib/utils";
import permissionMixin from '@/permissionMixin';
export default {
    mixins: [permissionMixin],
    name: "ModalDetailTicket",
    components: {Pagination},
    data () {
        return {
            entries: {},
            paginateDetail: {
                currentPage: 1,
                lastPage: 1,
            },
            ticketType: null,
            defaultValues:{
                filter: null,
                all: null,
                searchCode: '',
                searchName: '',
            },
        }   
    },
    created() {
    },
    methods: {
        async show (id = null, ticketType, content = null) {
            this.clearData();
            if (ticketType) {
                this.ticketType = ticketType;
                switch (ticketType) {
                    case this.$constant.TYPE_SRC_ORDER_SUPPLIER:
                        this.getDataOrderForSupplier(content);
                        break;
                    case this.$constant.TYPE_SRC_RETURN_IMPORT_SUPPLIER:
                        this.getDataReturnForSupplierById(id);
                        break;
                    case this.$constant.TYPE_SRC_SUPPLIER:
                        this.getDataSupplier(content);
                        break;
                    case this.$constant.TYPE_SRC_IMPORT_SUPPLIER:
                        this.getDataImportForSupplierById(id);
                        break;
                }
            }
        },
        async getDataOrderForSupplier(content){
            if(content){
                this.entries = [{...content}];
                if(this.entries){
                    this.entries.forEach(entry => {
                        if(entry.details){
                            this.defaultValues.filter = this.defaultValues.all = entry.details;
                            this.setPaginate(entry.details);
                        }
                    });
                }
                window.jQuery(this.$refs.modal).modal('show');
            }
        },
        async getDataSupplier(content){
            if(content){
                this.entries = [{...content}];
                window.jQuery(this.$refs.modal).modal('show');
            }
        },
        async getDataImportForSupplierById(id){
            const res = await $post('/v1/purchase-orders/show-import-supplier', {id: id});
            if(res && res.result){
                if(res.result.status && res.result.data != []){
                    this.entries = res.result.data;
                    if(this.entries){
                        this.entries.forEach(entry => {
                            if(entry.details){
                                this.defaultValues.filter = this.defaultValues.all = entry.details;
                                this.setPaginate(entry.details);
                            }
                        });
                    }
                    window.jQuery(this.$refs.modal).modal('show');
                }
            }
        },
        async getDataReturnForSupplierById(id){
            const res = await $post('/v1/purchase-orders/show-return-supplier', {id: id});
            if(res && res.result){
                if(res.result.status && res.result.data != []){
                    this.entries = res.result.data;
                    if(this.entries){
                        this.entries.forEach(entry => {
                            if(entry.details){
                                this.defaultValues.filter = this.defaultValues.all = entry.details;
                                this.setPaginate(entry.details);
                            }
                        });
                    }
                    window.jQuery(this.$refs.modal).modal('show');
                }
            }
        },
        hide () {
            this.clearData();
            window.jQuery(this.$refs.modal).modal('hide');
        },
        setPaginate(items){
            let arr = [];
            if(items){
                arr = items;
            }
            this.paginateDetail.currentPage = 1;
            this.paginateDetail.lastPage = Math.ceil(arr.length / 10);
            this.paginateDetail.totalRecord = arr.length;
        },
        onInputFilterDetailOrderSupplier(field){
            this.paginateDetail.currentPage = 1;
            if(this.defaultValues.searchCode.length == 0 && this.defaultValues.searchName.length == 0){
                this.defaultValues.filter = this.defaultValues.all;
                this.setPaginate(this.defaultValues.filter);
                return;
            }
            let arr = [];
            switch (field) {
                case 'searchCode':
                    for (let index = 0; index < this.defaultValues.all.length; index++) {
                        if (this.defaultValues.all[index].product != null && removeVietnameseTones(this.defaultValues.all[index].product?.code.toLowerCase()).indexOf(removeVietnameseTones(this.defaultValues.searchCode.toLowerCase())) > -1){
                            arr.push(this.defaultValues.all[index]);
                        }
                    }
                    break;
                case 'searchName':
                    for (let index = 0; index < this.defaultValues.all.length; index++) {
                        if (this.defaultValues.all[index].product != null && removeVietnameseTones(this.defaultValues.all[index].product?.name.toLowerCase()).indexOf(removeVietnameseTones(this.defaultValues.searchName.toLowerCase())) > -1){
                            arr.push(this.defaultValues.all[index]);
                        }
                    }
                    break;
            }
            this.defaultValues.filter = arr;
            this.setPaginate(arr);
        },
        clearData(){
            this.entries = {};
            this.paginateDetail = {
                currentPage: 1,
                lastPage: 1,
            };
            this.ticketType = null;
            this.defaultValues = {
                filter: null,
                all: null,
                searchCode: '',
                searchName: '',
            };
        },
        open(code){
            var route = null;
            switch (this.ticketType) {
                case this.$constant.TYPE_SRC_SETTLEMENT_ADVANCE:
                    route = this.$router.resolve({
                        path: '/transfer/index',
                        query: {
                            'code': code,
                        }
                    });
                    break;
                case this.$constant.TYPE_SRC_ORDER_SUPPLIER:
                    route = this.$router.resolve({
                        path: '/order-suppliers/index',
                        query: {
                            'order_supplier': code,
                        }
                    });
                    break;
                case this.$constant.TYPE_SRC_RETURN_IMPORT_SUPPLIER:
                    route = this.$router.resolve({
                        path: '/purchase-orders/return',
                        query: {
                            'return_purchase_order': code,
                        }
                    });
                    break;
                case this.$constant.TYPE_SRC_IMPORT_SUPPLIER:
                    route = this.$router.resolve({
                        path: '/purchase-orders/index',
                        query: {
                            'purchase_order': code,
                        }
                    });
                    break;
            }
            window.open(route.href, '_blank');
        },
        showCombo(entry){
            if(!entry.showCombo){
                entry.showCombo = true;
            }else{
                entry.showCombo = !entry.showCombo;
            }
        },
    },
    beforeUnmount() {
        this.hide();
        this.clearData();
    },
    unmounted() {
        this.clearData();
        this.hide();
    }
}
</script>
<style scoped>
#modalChecked {
    overflow-x: hidden;
    overflow-y: auto;
}

#modalTableChecked thead th {
    border-bottom: 0 !important;
}

@media (max-width: 1280px) {
    .modal-xl {
        width: auto !important;
    }
}

#noteRO {
    border-left: 1px solid #ccc;
}
.noteStyle {
    width: 100% !important;
    height: 120px !important;
    border: 0;
    border-radius: 0;
}
.noteStyle:focus {
    outline: none !important;
    border-color: none;
    box-shadow: 0 none;
}
.cate_selected{
    min-height: 30px !important;
    width:fit-content !important;
}
.area_obj_selected{
    max-height: 100px !important;
    min-height: 35px !important;
    overflow-y: auto;
}
.border-dotted-2{
    border: 1px dashed #ccc;
}
</style>