<template>
    <div ref="modal" class="modal mx-0 fade" tabindex="-1" role="dialog" data-backdrop="static">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header py-1 border-bottom-0">                   
                    <h6 class="modal-title py-2 px-4" >{{$t('message.DetailOrdersCard.title')}}</h6>
                    <button type="button" class="close" aria-label="Close" @click="hide()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>    
                <div class="modal-body py-0 px-3">                    
                    <div class="tab-content" id="myTabContent" v-if="this.entries?.length > 0">
                        <div class="tab-pane fade show active" v-for="entry in this.entries" :key="entry.id">                            
                            <div class="row mx-4 my-0 pb-0 mt-1">
                                <!-- Cột 1: : Mã đặt hàng nhập, Thời gian:, Nhà cung cấp, Người tạo: -->
                                <div class="col-4 pl-0">
                                    <div class="row border-bottom-1 m-0">                                       
                                        <div class="col-5 d-flex align-items-center px-0" style="width:150px;">{{$t('message.DetailOrdersCard.orderCode')}}:</div>
                                        <div class="col-7 pl-0">
                                            <div class="cate_selected d-flex align-items-center px-2 my-1 text-bold text-break" v-text="entry.code"></div>
                                        </div>
                                    </div>                                    
                                    <div class="row border-bottom-1 m-0">
                                        <div class="col-5 d-flex align-items-center pl-0 px-0">{{$t('message.DetailOrdersCard.orderDate')}}:</div>
                                        <div class="col-7 pl-0">
                                            <div class="cate_selected d-flex align-items-center px-2 my-1" v-text="dt(entry.purchaseDate)"></div>                                            
                                        </div>
                                    </div>
                                    <div class="row border-bottom-1 m-0">
                                        <div class="col-5 d-flex align-items-center pl-0 px-0">{{$t('message.DetailOrdersCard.customerName')}}:</div>
                                        <div class="col-7 pl-0 px-0">
                                            <div class="cate_selected d-flex align-items-center px-2 my-1 text-primary">
                                                <a :href="'/customers/index?code=' + entry.customerCode" target="_blank" class="text-decoration-none text-primary">{{ entry.customerName }}</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row border-bottom-1 m-0">
                                        <div class="col-5 d-flex align-items-center pl-0">{{$t('message.DetailOrdersCard.priceLists')}}:</div>
                                        <div class="col-7 pl-0">
                                            <div class="cate_selected d-flex align-items-center px-2 my-1" v-text="entry.priceListName ? entry.priceListName : null" ></div>
                                        </div>
                                    </div>
                                    <!-- Ngày giao dự kiến -->
                                    <div class="row border-bottom-1 m-0" >
                                        <div class="col-5 d-flex align-items-center px-0">{{$t('message.DetailOrdersCard.expectedDateOfImport')}}:</div>
                                        <div class="col-7 pl-0">
                                            <div class="cate_selected d-flex align-items-center px-2 my-1" >{{entry?.ExpectedDateOfTransfer ? d(entry.ExpectedDateOfTransfer) : null}}</div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Cột 2: :Trạng thái, Chi nhánh, Người đặt, Dự kiến nhập hàng -->
                                <div class="col-4 pl-0">                                   
                                    <div class="row border-bottom-1 m-0">
                                        <div class="col-5 d-flex align-items-center pl-0">{{$t('message.DetailOrdersCard.statusName')}}:</div>
                                        <div class="col-7 pl-0">
                                            <div class="cate_selected d-flex align-items-center px-2 my-1">
                                                {{ entry.statusName }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row border-bottom-1 m-0">
                                        <div class="col-5 d-flex align-items-center pl-0">{{$t('message.DetailOrdersCard.branchName')}}:</div>
                                        <div class="col-7 pl-0">                                            
                                            <div class="cate_selected d-flex align-items-center px-2 my-1">{{entry?.branchName ? entry.branchName : null}}</div>
                                        </div>
                                    </div>                                 
                                    <div class="row border-bottom-1 m-0">
                                        <div class="col-5 d-flex align-items-center pl-0">{{$t('message.DetailOrdersCard.branchNameCreate')}}:</div>
                                        <div class="col-7 pl-0">                                            
                                            <div class="cate_selected d-flex align-items-center px-2 my-1">{{entry?.branchName ? entry.branchName : null}}</div>
                                        </div>
                                    </div>
                                    <div class="row border-bottom-1 m-0" >
                                        <div class="col-5 d-flex align-items-center pl-0">{{$t('message.DetailOrdersCard.orderBy')}}:</div>
                                        <div class="col-7 pl-0">
                                            <div class="cate_selected d-flex align-items-center px-2 my-1" >{{entry.soldByName ? entry.soldByName : null}}</div>
                                        </div>
                                    </div>
                                    <div class="row border-bottom-1 m-0" >
                                        <div class="col-5 d-flex align-items-center pl-0">{{$t('message.DetailOrdersCard.createdBy')}}:</div>
                                        <div class="col-7 pl-0">
                                            <div class="cate_selected d-flex align-items-center px-2 my-1" >{{entry?.createdByName ? entry.createdByName : null}}</div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Cột 3: Ghi chú -->                                
                                <div class="col-4" id="noteRO">  
                                    <div class="row p-0 font-italic">
                                        <div class="col-12 text-gray">
                                            <span class="noteStyle text-gray ">
                                                {{ entry.description ? entry.description : (entry.note ? entry.note : $t('message.InvoiceIndex.note'))}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="row border-bottom-1 m-0" >
                                        <div class="col-5 d-flex align-items-center px-0">{{$t('message.DetailOrdersCard.SaleChannelName')}}:</div>
                                        <div class="col-7 pl-0">
                                            <div class="cate_selected d-flex align-items-center px-2 my-1" >{{entry?.saleChannelName ? entry.saleChannelName : null}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Bảng sản phẩm -->
                            <div class="mt-2 mx-4 pb-2 my-2">
                                <div class="row detail-prd-row mt-3 m-0 w-100">
                                    <div class="col-12 p-0">
                                        <table class="w-100">
                                            <thead>
                                                <tr class="backgroup__detail table-header border-1">
                                                    <th scope="col" class="pl-4 cell-code-coupon p-2"><span class="pl-2">{{$t('message.DetailOrdersCard.prodCode')}}</span></th>
                                                    <th scope="col" class="pl-3 cell-auto p-2" style="width:50px !important;">{{$t('message.DetailOrdersCard.prodName')}}</th>
                                                    <th scope="col" class="pl-3 cell-auto p-0" style="width:55px;">{{$t('message.DetailOrdersCard.prodUnit')}}</th>
                                                    <th scope="col" class="pl-3 cell-release-coupon p-2">{{$t('message.DetailOrdersCard.prodQuantity')}}</th>
                                                    <th scope="col" class="pl-0 cell-auto p-2" style="width:55px;">{{$t('message.DetailOrdersCard.prodPrice')}}</th>
                                                    <th scope="col" class="pl-3 cell-auto p-2">{{$t('message.DetailOrdersCard.prodDiscount')}}</th>
                                                    <th scope="col" class="pl-3 cell-auto p-2">{{$t('message.DetailOrdersCard.prodImportPrice')}}</th>
                                                    <th scope="col" class="pl-3 cell-status-coupon p-2">{{$t('message.DetailOrdersCard.totalPrice')}}</th>
                                                </tr>                                               
                                            </thead>
                                            <tbody>
                                                <tr class="table-header border">
                                                    <th scope="col" class="pl-3 py-2"> 
                                                        <input type="text" v-model="this.defaultValues.searchCode" @input="onInputFilterDetailOrderSupplier('searchCode')" :placeholder="$t('message.DetailOrdersCard.searchProductCode')" class="border-none bg-transparent">
                                                    </th>
                                                    <th scope="col" class="pl-3 py-2"> 
                                                        <input type="text" v-model="this.defaultValues.searchName" @input="onInputFilterDetailOrderSupplier('searchName')" :placeholder="$t('message.DetailOrdersCard.searchProductName')" class="border-none bg-transparent">
                                                    </th>
                                                    <th colspan="6"></th>
                                                </tr>
                                                <template v-if="this.defaultValues.filter?.length > 0">
                                                    <template v-for="(item, index) in this.defaultValues.filter" :key="item.id">    
                                                        <!-- Sản phẩm thường -->
                                                        <tr class="table-header border"  :class="index % 2 ? 'bg-white' : 'bg-light'">
                                                            <template v-if="index >= (this.paginateDetail.currentPage - 1)*10 && index < this.paginateDetail.currentPage*10">
                                                                <!-- Mã hàng hóa -->
                                                                <th scope="col" class="pl-3 cell-code-coupon">
                                                                    <div class="row m-0 d-flex">
                                                                        <div class="col-1 p-0">
                                                                            <i class="fa fa-chevron-down" v-if="item.children_combo && item.children_combo.length > 0 && !item.showCombo" @click="showCombo(item)"></i>
                                                                            <i class="fa fa-chevron-up" v-if="item.children_combo && item.children_combo.length > 0 && item.showCombo" @click="showCombo(item)"></i>
                                                                        </div>
                                                                        <div class="col-10 p-0">
                                                                            <a :href="'/products/index?code=' + item.product.code" target="_blank" class="text-decoration-none text-primary">{{item.product.code}}</a>
                                                                        </div>
                                                                    </div>
                                                                </th>
                                                                <!-- Tên hàng hóa + ghi chú hàng hóa -->
                                                                <th scope="col" class="pl-3 cell-auto p-2 font-weight-normal">
                                                                    <span v-text="item.product.name"></span>                                                                        
                                                                </th>
                                                                <th scope="col" class="pl-3 cell-auto p-2 font-weight-normal" v-text="item?.product_unit && item.product_unit?.unitName ? item.product_unit.unitName : null"></th>
                                                                <th scope="col" class="pl-3 cell-release-coupon p-2 font-weight-normal"> {{n(item.quantity)}}/<span class="text-danger">{{n(item.quantityInvoice)}}</span></th>
                                                                <th scope="col" class="pl-3 cell-auto p-2 font-weight-normal" v-text="n(item.price)"></th>
                                                                <th scope="col" class="pl-3 cell-auto p-2 font-weight-normal" v-text="n(item.discount)"></th>
                                                                <th scope="col" class="pl-3 cell-auto p-2 font-weight-normal" v-text="n(item.price - item.discount)"></th>
                                                                <th scope="col" class="pl-3 cell-status-coupon p-2 font-weight-normal" v-text="n(item.subTotal)"></th>
                                                            </template>                                                            
                                                        </tr>     
                                                        <!-- plus  -->
                                                        <template v-if="item?.plusOrder">                                                           
                                                            <tr class="table-header border"  :class="index % 2 ? 'bg-white' : 'bg-light'" v-for="(itemPlus) in item.plusOrder" :key="itemPlus.id">
                                                                <th scope="col" class="pl-3 cell-code-coupon py-2">
                                                                   <div class="row m-0 d-flex">
                                                                        <div class="col-1 p-0">
                                                                            <i class="fa fa-chevron-down" v-if="item.children_combo && item.children_combo.length > 0 && !item.showCombo" @click="showCombo(item)"></i>
                                                                            <i class="fa fa-chevron-up" v-if="item.children_combo && item.children_combo.length > 0 && item.showCombo" @click="showCombo(item)"></i>
                                                                        </div>
                                                                        <div class="col-10 p-0">
                                                                            <a :href="'/products/index?code=' + itemPlus.code" target="_blank" class="text-decoration-none text-primary">{{itemPlus.code}}</a>
                                                                        </div>
                                                                    </div>
                                                                </th>
                                                                <th scope="col" class="pl-3 cell-auto p-2 font-weight-normal">
                                                                    <span v-text="itemPlus.name"></span> 
                                                                </th>
                                                                <th scope="col" class="pl-3 cell-auto p-2 font-weight-normal" v-text="itemPlus.productUnitText"></th>
                                                                <th scope="col" class="pl-3 cell-release-coupon p-2 font-weight-normal"> {{n(itemPlus.quantity)}}/<span class="text-danger">{{n(item.quantityInvoice)}}</span> </th>
                                                                <th scope="col" class="pl-3 cell-auto p-2 font-weight-normal" v-text="n(itemPlus.price)"></th>
                                                                <th scope="col" class="pl-3 cell-auto p-2 font-weight-normal" v-text="n(itemPlus.discount)"></th>
                                                                <th scope="col" class="pl-3 cell-auto p-2 font-weight-normal" v-text="n(itemPlus.price - itemPlus.discount)"></th>
                                                                <th scope="col" class="pl-3 cell-status-coupon p-2 font-weight-normal" v-text="n(itemPlus.price * itemPlus.quantity)"></th>
                                                            </tr>
                                                           
                                                        </template>
                                                    </template>
                                                </template>
                                               
                                            </tbody>
                                        </table>
                                        <Pagination :value="paginateDetail" :record="10" v-if="this.paginateDetail.totalRecord >= 10"/>
                                        <div class="no-data border border-top-0" v-if="entry.invoice_items?.length == 0 || this.defaultValues.filter?.length == 0">
                                            <span>{{$t('message.DetailOrdersCard.notFound')}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Tổng giá trị -->
                            <div class="row mx-4 my-0 pb-4 mt-0">
                                <div class="col-6">
                                    <div class="row mb-2" v-show="entry.coupon_detail">
                                        <div class="col-12 p-3 border-dotted-2 rounded">
                                            <p class="text-bold m-0">
                                                <i class="fa-solid fa-badge-percent"></i>{{$t('message.DetailOrdersCard.codeDiscount')}}
                                            </p>
                                            <span v-if="entry.messageCoupon" v-text="entry.messageCoupon"></span>
                                        </div>
                                    </div>
                                    <div class="row" v-show="entry.messagePromotion">
                                        <div class="col-12 p-3 border-dotted-2 rounded">
                                            <p class="text-bold m-0">
                                                <span class="btn-icon-gift mr-2 text-danger"><i class="fa fa-gift"></i></span>
                                                {{$t('message.DetailOrdersCard.promotion')}}
                                            </p>
                                            <span v-text="entry.messagePromotion"></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6" >
                                    <div class="row m-2 mr-0">
                                        <div class="col-10 flex-end">{{$t('message.DetailOrdersCard.totalQuantity')}}:</div>
                                        <div class="col-2 p-0 flex-end"> {{n(entry.totalQuantity)}}</div>
                                    </div>                                    
                                    <div class="row m-2 mr-0">
                                        <div class="col-10 flex-end">{{$t('message.DetailOrdersCard.total')}}:</div>
                                        <div class="col-2 p-0 flex-end" v-text="n(entry.totalOrigin)"></div>
                                    </div>
                                    <div class="row m-2 mr-0">
                                        <div class="col-10 flex-end">{{$t('message.DetailOrdersCard.discount')}}:</div>
                                        <div class="col-2 p-0 flex-end" v-text="n(entry.discountValue)"></div>
                                    </div>
                                    <div class="row m-2 mr-0">
                                        <div class="col-10 flex-end">{{$t('message.DetailOrdersCard.sumTotal')}}:</div>
                                        <div class="col-2 p-0 flex-end" v-text="n(entry.total)"></div>
                                    </div>
                                    <div class="row m-2 mr-0">
                                        <div class="col-10 flex-end">{{$t('message.DetailOrdersCard.paymentForSupplier')}}:</div>
                                        <div class="col-2 p-0 flex-end" >
                                            <span v-if="entry.totalPayment > entry.total">{{n(entry.total)}}</span>
                                            <span v-else>{{n(entry.totalPayment)}}</span>
                                        </div>
                                    </div>
                                    <div class="row flex-end mt-4" >
                                        <button type="button" class="btn btn-save mx-1" @click="open(entry.code)">
                                            <i class="fa fa-share mr-2"></i>{{$t('message.DetailOrdersCard.btnOpen')}}
                                        </button>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import {$post, removeVietnameseTones, getSearchQuery} from "@/lib/utils";
import permissionMixin from '@/permissionMixin';
import store from "@/store"; 
import {explode} from "@/lib/utils";

export default {
    mixins: [permissionMixin],
    name: "DetailOrdersCard",
    components: {Pagination},
    data () {
        const query = getSearchQuery();
        const branchIds = query.branch_ids !== undefined ? explode(query.branch_ids) : ([store.state.CurrentBranch.id] || []);
        return {
            branch_ids: branchIds,
            entries: {},
            paginateDetail: {
                currentPage: 1,
                lastPage: 1,
            },
            stockCardType: null,
            defaultValues:{
                filter: null,
                all: null,
                searchCode: '',
                searchName: '',
            },
            userId: store.state.Auth.user.id,         
        }   
    },
    created() {
    },
    methods: {
        async show (code) {
            this.clearData();
            if (code) {               
                this.getDataOrderForSupplierById(code);   
            }
        },
        totalQuantity(details){            
            let total = 0;
            details.forEach(d => {
                total = total + d.quantity;
                if(d.plusOrder){
                   total = total + this.plusTotalQuantity(d.plusOrder)
                }
            });            
            return total;
        },
        plusTotalQuantity(plus){
            let total = 0;
            plus.forEach(p => {
                total = total + p.quantity;           
            });
            return total;
        },
        plusTotalProduct(plus){
            let total = 0;            
            return total;
        },      
        async getDataOrderForSupplierById(code){                        
            const res = await $post('/v1/orders/get-order-detail', {code: code, type: this.$constant.typeOrder, branchId: this.branch_ids});            
            if(res && res.status.code == 200){
                if(res.result){                                      
                    this.entries = [res.result];
                    if(this.entries){       
                        this.entries.forEach(entry => {
                            if(entry.products){
                                entry.totalQuantity = this.totalQuantity(entry.products);
                                this.defaultValues.filter = this.defaultValues.all = entry.products;   
                                this.setPaginate(entry.products);
                            }
                            else {
                                entry.totalQuantity = 0;
                            }
                        });
                    }                    
                    window.jQuery(this.$refs.modal).modal('show');
                }
            }
        },

        hide () {
            this.clearData();
            window.jQuery(this.$refs.modal).modal('hide');
        },
        setPaginate(items){
            let arr = [];
            if(items){
                arr = items;
            }
            this.paginateDetail.currentPage = 1;
            this.paginateDetail.lastPage = Math.ceil(arr.length / 10);
            this.paginateDetail.totalRecord = arr.length;
        },
        onInputFilterDetailOrderSupplier(field){
            this.paginateDetail.currentPage = 1;
            if(this.defaultValues.searchCode.length == 0 && this.defaultValues.searchName.length == 0){
                this.defaultValues.filter = this.defaultValues.all;
                this.setPaginate(this.defaultValues.filter);
                return;
            }
            let arr = [];
            switch (field) {
                case 'searchCode':
                    for (let index = 0; index < this.defaultValues.all.length; index++) {
                        if (this.defaultValues.all[index].product != null && removeVietnameseTones(this.defaultValues.all[index].product?.code.toLowerCase()).indexOf(removeVietnameseTones(this.defaultValues.searchCode.toLowerCase())) > -1){
                            arr.push(this.defaultValues.all[index]);
                        }
                    }
                    break;
                case 'searchName':
                    for (let index = 0; index < this.defaultValues.all.length; index++) {
                        if (this.defaultValues.all[index].product != null && removeVietnameseTones(this.defaultValues.all[index].product?.name.toLowerCase()).indexOf(removeVietnameseTones(this.defaultValues.searchName.toLowerCase())) > -1){
                            arr.push(this.defaultValues.all[index]);
                        }
                    }
                    break;
            }
            this.defaultValues.filter = arr;
            this.setPaginate(arr);
        },
        clearData(){
            this.entries = {};
            this.paginateDetail = {
                currentPage: 1,
                lastPage: 1,
            };
            this.stockCardType = null;
            this.defaultValues = {
                filter: null,
                all: null,
                searchCode: '',
                searchName: '',
            };
        },
        open(code){
            var route = null;
            route = this.$router.resolve({
                        path: '/orders/index',
                        query: {
                            'code': code,
                        }
                    });
            window.open(route.href, '_blank');
        },
        showCombo(entry){
            if(!entry.showCombo){
                entry.showCombo = true;
            }else{
                entry.showCombo = !entry.showCombo;
            }
        },
    },
    beforeUnmount() {
        this.clearData();
    },
    unmounted() {
        this.clearData();
    }
}
</script>
<style scoped>
#modalChecked {
    overflow-x: hidden;
    overflow-y: auto;
}

#modalTableChecked thead th {
    border-bottom: 0 !important;
}

@media (max-width: 1280px) {
    .modal-xl {
        width: auto !important;
    }
}

#noteRO {
    border-left: 1px solid #ccc;
}
.noteStyle {
    width: 100% !important;
    height: 120px !important;
    border: 0;
    border-radius: 0;
}
.noteStyle:focus {
    outline: none !important;
    border-color: none;
    box-shadow: 0 none;
}
.cate_selected{
    min-height: 30px !important;
    width:fit-content !important;
}
.area_obj_selected{
    max-height: 100px !important;
    min-height: 35px !important;
    overflow-y: auto;
}
.border-dotted-2{
    border: 1px dashed #ccc;
}
.font-italic {
    height: 9.7rem !important;
}
</style>